import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';
import {userprofileeditsuceess} from '../actions/Index'

const initialState = {
    error: null,
    isloding: false,
}


const reducer = (state=initialState,action) => 
{
    switch(action.type) 
    {
        case actiontype.USERPROFILEEDIT_START: return mergObject(state,{error:null,isloding:true})
        case actiontype.USERPROFILEEDIT_SUCCESS : return userprofileeditsuceess(state,action)
        case actiontype.USERPROFILEEDIT_FAIL: return mergObject(state,{error:action.error,isloding:false})
        default : return state;
    }
}

export default reducer