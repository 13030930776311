import https from "../../utility/api-client";

class Apicrationmtafive
{
    apivrationmetafive(params)
    {
        return https.post('/MetaTrader/PostMtLogin', params);
    }
}

export default new Apicrationmtafive();




