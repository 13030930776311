import https from '../../utility/api-client'

class FyersPostdesibalservices
{
    
    fyesrpostdisable(params)
    {
        return https.post('Fyers/PostFidDisable', params);
    }
}

export default new FyersPostdesibalservices();