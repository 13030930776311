
import https from "../../utility/api-client";

class Dashstgworking
{
    dashstgworking(userid)
    {
        return https.get('/Fyers/GetStraACount?UserId='+userid);
    }
}

export default new Dashstgworking();
