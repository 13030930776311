import https from '../../utility/api-client'

class Checkemail
{
    
    chkmemail(Email)
    {
        return https.get('/User/GetEmail?Email='+ Email);
    }
}

export default new Checkemail();