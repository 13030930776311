import * as actiontype from '../actions/Actions';
import { mergObject } from '../../utility/util';


const initialState = {
    totalamount: null,
    slist: null,
    sttotal:null,
    error: null,
    isloding: false,

}

const satageyoptain = (state, action) => {
    return mergObject(state, {
        totalamount: action.totalamount,
        slist: action.slist,
        sttotal:action.sttotal,
        error: null,
        isloding: false,

    });
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actiontype.STATARGEY_OPTAIN: return satageyoptain(state, action)
        default: return state;
    }
}

export default reducer