import { Form, Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
const Subcriptionplan = () => {

    const formik = useFormik({
        initialValues: {
            radio: "",
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            radio: Yup.number()
                .oneOf([1], "Please Selcet one of subcripation")

        }),

    })
    return (
        
        <form onSubmit={formik.submitForm}>

        <section className="subcription-section">
            <div className="subcription-list">
                <div className="subcription-item">
                    <input className="radiobutton" type="radio" name="radio" id="1month" />
                    <label htmlFor="1month"> 1 - Month</label>
                    <label>Rs.999</label>
                    <span className="suboffer badge rounded-pill bg-info">0% off</span>
                </div>
                <div className="subcription-item">
                    <input className="radiobutton" type="radio" name="radio" id="3month"
                        onChange={formik.handleChange}
                        value={formik.values.radio}
                        onBlur={formik.handleBlur} />
                    <label htmlFor="3month">3 - Month</label>
                    <label>Rs 2697.3</label>
                    <span className="suboffer badge rounded-pill bg-info">0% off</span>
                </div>
                <div className="subcription-item">
                    <input className="radiobutton" type="radio" name="radio" id="6month"
                        onChange={formik.handleChange}
                        value={formik.values.radio}
                        onBlur={formik.handleBlur} />
                    <label htmlFor="6month">6 - Month</label>
                    <label>Rs 4795.2</label>
                    <span className="suboffer badge rounded-pill bg-info">0% off</span>
                </div>
                <div className="subcription-item">
                    <input className="radiobutton" type="radio" name="radio" id="12month"
                        onChange={formik.handleChange}
                        value={formik.values.radio}
                        onBlur={formik.handleBlur} />
                    <label htmlFor="12month">12 - Month</label>
                    <label>Rs 8391.6</label>
                    <span className="suboffer badge rounded-pill bg-info">0% off</span>
                </div>
                <button className="btn btn-primary" disabled={!formik.isValid} type="submit" >Place Order</button>
            </div>
        </section>
    </form>
    )
}

export default Subcriptionplan