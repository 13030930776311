import { Tabs } from 'antd';
import Orderpage from "../component/Page_Order"
import Positionpage from "../component/Page_Position"
import { Button, ConfigProvider, Input, Radio, Switch, theme } from 'antd';
import Positionservices from "../store/services/Positionservice"
import  Header  from "../component/Header"
import { useState } from 'react';
const Trade = () => {

	const [currentthem,setCurrentthem] = useState("light")
	const lighttheem = {
		colorPrimary:"green",
		
	}

	const darkthem = {
		colorPrimary:"black",
		
	}

	const items = [
		{
			key: '1',
			label: "Order",
			children: <Orderpage />,
		},
		{
			key: '2',
			label: "Position",
			children: <Positionpage />,
		},

	];

	return (
		<>
			<div className='trading py-5' style={{marginTop:"150px"}}>
				<ConfigProvider 
				theme={{
					token:{
						colorPrimary:"orange",	
				
					},
	
				}}
				>
					<Tabs
						defaultActiveKey="1"
						size={"large"}
						items={items}
					/>;
					
				</ConfigProvider>

			</div>
		</>
	)
}

export default Trade