import https from '../../utility/api-client'

class Positionservices
{
    
    positionser(userid)
    {
        return https.get('/Fyers/GetTrade?UserId='+userid);
    }
}

export default new Positionservices();