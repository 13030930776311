import { red } from '@mui/material/colors';
import { color } from '@mui/system';
import { Space, Switch, Table, Button, Form, Input, Checkbox, Select } from 'antd';
import Header from './Header';
import { useEffect, useState, useRef } from 'react';
import Fetchtradidinglist from '../store/services/fetchtradidinglist';
import Highlighter from 'react-highlight-words';
import Combouser from "../store/services/combouser"
import Comboappid from "../store/services/comboappid"
import Combostatargey from "../store/services/combostrategy"
import Comboticker from "../store/services/comboticker"
import Comboinstument from "../store/services/comboinstrument"
import Loaidngcom from "../component/loadingcomponent"
import { Collapse } from 'antd';
import Fayersexitpostion from "../store/services/Fayersexitpostion"
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col } from 'antd'
const { Panel } = Collapse;


// rowSelection objects indicates the need for row selection
const Tradiviewlist = () => {
	debugger
	// const [searchText, setSearchText] = useState('');
	// const [searchedColumn, setSearchedColumn] = useState('');
	// const searchInput = useRef(null);
	// const handleSearch = (selectedKeys, confirm, dataIndex) => {
	// 	debugger
	// 	confirm();
	// 	setSearchText(selectedKeys[0]);
	// 	setSearchedColumn(dataIndex);
	// };
	// const handleReset = (clearFilters) => {
	// 	debugger
	// 	clearFilters();
	// 	setSearchText('');

	// };
	// const getColumnSearchProps = (dataIndex) => ({
	// 	filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
	// 		<div
	// 			style={{
	// 				padding: 8,
	// 			}}
	// 			onKeyDown={(e) => e.stopPropagation()}
	// 		>
	// 			<Input
	// 				ref={searchInput}
	// 				placeholder={`Search ${dataIndex}`}
	// 				value={selectedKeys[0]}
	// 				onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
	// 				onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
	// 				style={{
	// 					marginBottom: 8,
	// 					display: 'block',
	// 				}}
	// 			/>
	// 			<Space>
	// 				<Button
	// 					type="primary"
	// 					onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
	// 					icon={<SearchOutlined />}
	// 					size="small"
	// 					style={{
	// 						width: 90,
	// 					}}
	// 				>
	// 					Search
	// 				</Button>

	// 				<Button

	// 					onClick={() => clearFilters && handleReset(clearFilters)}
	// 					size="small"
	// 					style={{
	// 						width: 90,
	// 					}}
	// 				>
	// 					Reset
	// 				</Button>
	// 				<Button
	// 					type="link"
	// 					size="small"
	// 					onClick={() => {
	// 						confirm({
	// 							closeDropdown: false,
	// 						});
	// 						setSearchText(selectedKeys[0]);
	// 						setSearchedColumn(dataIndex);
	// 					}}
	// 				>
	// 					Filter
	// 				</Button>
	// 				<Button
	// 					type="link"
	// 					size="small"
	// 					onClick={() => {
	// 						close();
	// 					}}
	// 				>
	// 					close
	// 				</Button>
	// 			</Space>
	// 		</div>
	// 	),
	// 	filterIcon: (filtered) => (
	// 		<SearchOutlined
	// 			style={{
	// 				color: filtered ? '#1890ff' : undefined,
	// 			}}
	// 		/>
	// 	),
	// 	onFilter: (value, record) =>
	// 		record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
	// 	onFilterDropdownOpenChange: (visible) => {
	// 		debugger
	// 		if (visible) {
	// 			setTimeout(() => searchInput.current?.select(), 100);
	// 		}
	// 	},
	// 	render: (text) =>
	// 		searchedColumn === dataIndex ? (
	// 			<Highlighter
	// 				highlightStyle={{
	// 					backgroundColor: '#ffc069',
	// 					padding: 0,
	// 				}}
	// 				searchWords={[searchText]}
	// 				de
	// 				autoEscape
	// 				textToHighlight={text ? text.toString() : ''}
	// 			/>
	// 		) : (
	// 			text
	// 		),
	// })

	const columns = [
		{
			title: 'User name',
			dataIndex: 'userName',
			key: 'username',
			width: '15%',
		},
		{
			title: 'ClientId',
			dataIndex: 'clientId',
			key: 'name',
			width: '15%',
		},
		{
			title: 'Stra name',
			dataIndex: 'straName',
			key: 'name',
			width: '15%',
		},

		{
			title: 'Symbol',
			dataIndex: 'symbol',
			key: 'age',
			width: '15%',
		},
		{
			title: 'Id',
			dataIndex: 'tId',
			width: '5%',
			key: 'tId',
		},
		{
			title: 'Date',
			dataIndex: 'tDate',
			width: '20%',
			key: 'tDate',
		},
		{
			title: 'Instrument',
			dataIndex: 'tInstrument',
			width: '15%',
			key: 'tInstrument',
		},
		{
			title: 'Product',
			dataIndex: 'tProduct',
			width: '5%',
			key: 'tProduct',
		},
		{
			title: 'StraType',
			dataIndex: 'straType',
			width: '3%',
			key: 'straType',
		},
		{
			title: 'Buy Qty',
			dataIndex: 'tBuyQty',
			width: '3%',
			key: 'tBuyQty',

		},
		{
			title: 'Sell Qty',
			dataIndex: 'tSellQty',
			width: '3%',
			key: 'tSellQty',
		},
		{
			title: 'Total Qty',
			dataIndex: 'tQty',
			width: '3%',
			key: 'tQty',
		},
		{
			title: 'Action',
			dataIndex: '',
			key: 'x',
			render: (_, record) =>
				datasource.length >= 1 ? (
					<Button type='primary' onClick={() => getrecored(record)}>
						Exit
					</Button>

				) : null,
		},
	];

	const [datasource, setDatasource] = useState([])
	const [revercersorderstatus, setRevercerorderstatus] = useState("false")

	const [combouserlist, setCombouserlist] = useState([])
	const [comboappidlist, setComboappidlist] = useState([])
	const [combostglist, setCombostglist] = useState([])
	const [combotikerlist, setCombotikerlist] = useState([])
	const [comboinstrumentlist, setComboinstrumentlist] = useState([])

	const [user, setUser] = useState(0)
	const [appid, setappid] = useState(0)
	const [statargey, setStatragey] = useState(0)
	const [tiker, setTikerid] = useState("A")
	const [Instrument, setInstrument] = useState("A")

	// fill User list
	useEffect(() => {
		debugger
		let params = null
		Combouser.fillusers(params).then((apires) => {
			<Loaidngcom />
			setCombouserlist(apires.data.data)

		})
			.catch((error) => {
				console.log("faild")
				debugger
			})
	}, [])


	useEffect(() => {
		debugger
		let params = null
		Comboappid.fillappid(params).then((apires) => {
			<Loaidngcom />
			debugger

			setComboappidlist(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")
				debugger
			})
	}, [])


	useEffect(() => {
		debugger
		let params = null
		Combostatargey.fillstatargey(params).then((apires) => {
			<Loaidngcom />
			debugger
			setCombostglist(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")
				debugger
			})
	}, [])

	useEffect(() => {
		debugger
		let params = null
		Comboticker.filltiker(params).then((apires) => {
			<Loaidngcom />
			debugger
			setCombotikerlist(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")
				debugger
			})
	}, [])


	useEffect(() => {
		debugger
		let params = null
		Comboinstument.fillinsrument(params).then((apires) => {
			<Loaidngcom />
			setComboinstrumentlist(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")
				debugger
			})
	}, [])


	// useEffect(()=>{
	// 	debugger
	// 	Fetchtradidinglist.fetchtradelist(0, 0, 0, "A", "A").then((apires) => {
	// 		debugger
	// 		setDatasource(apires.data.data)
	// 	})
	// 		.catch((error) => {
	// 			console.log("faild")
	// 			debugger
	// 		})
	// },[datasource])

	const showdata = () => {
		debugger
		if (user == "") {
			setUser(0)
		}
		if (appid == "") {
			setappid(0)
		}
		if (statargey == "") {
			setStatragey(0)
		}
		if (tiker == "") {
			setTikerid("A")
		}

		if (Instrument == "") {
			setInstrument("A")
		}

		Fetchtradidinglist.fetchtradelist(user, appid, statargey, tiker, Instrument).then((apires) => {
			debugger
			<Loaidngcom />
			setDatasource(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")
				debugger
			})
	}

	const Resetdata = (e) => {
		debugger
		setUser(e.target.value)
		setappid("")
		setStatragey("")
		setTikerid("")
		setInstrument("")
	}

	const getrecored = (record) => {
		debugger
		let params = null
		if (record.userName !== undefined) {
			params = { UserId: record.userId, FyersId: "0", StraId: "0", Symbol: "0", TInstrument: "0", ReverceEntry: revercersorderstatus }
		}

		if (record.clientId !== undefined) {
			//	alert("do you want exit from " + record.userId + ' and ' + record.clientId)
			params = { UserId: record.userId, FyersId: record.fyersID, StraId: "0", Symbol: "0", TInstrument: "0", ReverceEntry: revercersorderstatus }
		}

		if (record.straName !== undefined) {
			//	alert("do you want exit from " + record.userId + ' ' + record.straId)
			params = { UserId: record.userId, FyersId: record.fyersID, StraId: record.straId, Symbol: "0", TInstrument: "0", ReverceEntry: revercersorderstatus }
		}

		if (record.symbol !== undefined) {
			//alert("do you want exit from " + record.userId + ' and ' + record.straId + ' and ' + record.symbol)
			params = { UserId: record.userId, FyersId: record.fyersID, StraId: record.straId, Symbol: record.symbol, TInstrument: "0", ReverceEntry: revercersorderstatus }
		}

		if (record.tInstrument !== undefined) {
			//alert("do you want exit from " + record.userId + ' ' + record.straId + ' ' + record.symbol + ' ' + record.tInstrument)
			params = { UserId: record.userId, FyersId: record.fyersID, StraId: record.straId, Symbol: record.symbolId, TInstrument: record.tInstrument, ReverceEntry: revercersorderstatus }
		}


		Fayersexitpostion.exitposition(params).then((apiRes) => {
			debugger
			toast.info(apiRes.data.message, {
				position: "top-center"
			})

			setDatasource("")


		})
			.catch((error) => {
				console.log("faild")
				debugger
			})
	}


	return (
		<div style={{marginTop:"110px"}}>

			<Collapse >
				<Panel collapsible="false" header="click for Serch Itme Heare" key="1" >
					<div className='row filterdiv'>
						<Row gutter={10}>
							<Col xs={24} sm={24} md={4} lg={4} xl={4}>
								<label> User Name :-</label>
								<select placeholder='UserName' className="form-select" onChange={(e) => { setUser(e.target.value) }}>
									<option>0</option>
									{combouserlist.map(user => (
										<option
											value={user.id}
										>{user.profileName}</option>
									))}
								</select>
							</Col>
							<Col xs={24} sm={24} md={4} lg={4} xl={4}>
								
								<label> Appid:-</label>
								<select className="form-select" onChange={(e) => { setappid(e.target.value) }}>

									<option >0</option>
									{comboappidlist.map(app => (
										<option
											value={app.fyersId}
										>{app.appId}</option>
									))}
								</select>
							</Col>
							<Col xs={24} sm={24} md={4} lg={4} xl={4}>
							<label> Strategy Name :-</label>
								<select className="form-select" onChange={(e) => { setStatragey(e.target.value) }}>
									<option>0</option>
									{combostglist.map(stg => (
										<option
											value={stg.straId}
										>{stg.straName}</option>
									))}
								</select>
							</Col>
							<Col xs={24} sm={24} md={4} lg={4} xl={4}>
							<label> Ticker:-</label>
								<select className="form-select" onChange={(e) => { setTikerid(e.target.value) }}>
									<option >A</option>
									{combotikerlist.map(tiker => (
										<option
											value={tiker.ticker}
										>{tiker.ticker}</option>
									))}
								</select>
							</Col>
							<Col xs={24} sm={24} md={4} lg={4} xl={4}>
							<label> instrument:-</label>
								<select className="form-select" onChange={(e) => { setInstrument(e.target.value) }}>
									<option >A</option>
									{comboinstrumentlist.map(insrument => (
										<option
											value={insrument.tInstrument}
										>{insrument.tInstrument}</option>
									))}
								</select>
							</Col>
						</Row>

					</div>
					<Row gutter={10}>
						<div className='btndiv'>
							<Col xs={24} sm={24} md={4} lg={4} xl={4}>
								<Button type='primary' onClick={() => { showdata() }} >Serch Heare</Button>
							</Col>
						</div>
						<div className='btndiv'>
							<Row gutter={10}>
								<Col xs={24} sm={24} md={4} lg={4} xl={4}>
									<Button type='primary' onClick={(e) => { Resetdata(e) }} >Reset</Button>
								</Col>
							</Row>
						</div>
						<div className='btndiv'>
							<Col xs={24} sm={24} md={4} lg={4} xl={4}>
								<Form>
									<Checkbox onClick={(e) => { setRevercerorderstatus(e.target.checked) }}>Reverce Order</Checkbox>
									<Button type='primary'>click Heare</Button>
								</Form>
							</Col>

						</div>

					</Row>

				</Panel>
			</Collapse>

			<div className='tradinglist'>
				<Table
					bordered={true}
					columns={columns}
					dataSource={datasource}
					scroll={{ x: true }}
				/>
			</div>

			<ToastContainer
				position="top-center"
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>

		</div>
	);
};

export default Tradiviewlist