import http from '../../utility/api-client'

class AuthService
{
    
    login(params)

    {
        debugger
        return http.post('/User/PostLogin',params);
    }
}

export default new AuthService();