import https from '../../utility/api-client'

class Checkuser
{
    
    chkuser(id)
    {
        return https.get('/User/GetUser?Id='+ id);
    }
}

export default new Checkuser();