import https from '../../utility/api-client'

class Meta5Apicount
{
    
    meta5apicount(uid)
    {
        return https.get('/MetaTrader/GetMtApiCount?UserId='+uid);
    }
}

export default new Meta5Apicount();