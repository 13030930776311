import https from '../../utility/api-client'

class Combofillstatargey
{
    
    fillstatargey(params)
    {
        return https.get('/Admin/GetComboStrategy',params);
    }
}

export default new Combofillstatargey();