import https from "../../utility/api-client";

class Apicration
{
    apicre(params)
    {
        return https.post('/Fyers/PostFlogin', params);
    }
}

export default new Apicration();




