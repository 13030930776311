import https from '../../utility/api-client'

class Combofillinsrtument
{
    
    fillinsrument(params)
    {
        return https.get('/Admin/GetComboInstrument',params);
    }
}

export default new Combofillinsrtument();