import https from '../../utility/api-client'

class Posttradingdata
{
    
    posttradingdata(params)
    {
        return https.post('Webhook/TradingView',params);
    }
}

export default new Posttradingdata();