import https from '../../utility/api-client'

class Addonstatrgeylistser
{
    
    addstglist(userid,invid)
    {
        return https.get('/Inv/GetInvStrategy?user_id='+userid+'&'+invid);   
    }
}

export default new Addonstatrgeylistser();