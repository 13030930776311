import https from "../../utility/api-client";

class Regservices
{
    reg(params)
    {
        return https.post('/User/PostUser',params)
    }
}
export default new Regservices();


