import https from '../../utility/api-client'

class Meta5APIlist
{
    
   meta5acetivelist(uid,fid)
    {
        return https.get('/MetaTrader/GetMtAccounts?UserId='+uid+'&mtid='+fid);
    }
}

export default new Meta5APIlist();