import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../store/actions/Index'
import chekuser from '../store/services/chekuser'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Nav, NavDropdown } from 'react-bootstrap';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Avatar, Badge,notification,Menu } from 'antd';
import { red } from '@mui/material/colors';
import {
	BellOutlined, UserOutlined, PoweroffOutlined, WalletOutlined, HomeOutlined, DashboardOutlined
} from '@ant-design/icons';


const Header = () => {

	const [adminuse, setAdminuse] = useState(true)
	const [messages, setMessages] = useState([]);
	const [isNotificationListVisible, setNotificationListVisible] = useState(false);
	const authState = useSelector(state => state.authState)
	// if (authState.auth) {

	// }



	let userId = localStorage.getItem('userid');
	let Username = localStorage.getItem('username')
	let Auth = localStorage.getItem('auth')

	let dispatch = useDispatch()
	const history = useHistory()
	if (userId == null) {


		let path = "/SignIn";
		history.push(path);
	}
	const ColorList = ['Green', 'red', 'Blue', 'Pink']

	let username = Username
	let Aavtarname = username.charAt(0);
	const [user, setUser] = useState(Aavtarname);
	const [color, setColor] = useState(ColorList[0]);


	// useEffect(() => {
	// 	debugger
	// 	const ws = new WebSocket('wss://websocket.asiabullalgo.in/ws/mstplan'); // Replace with your actual WebSocket URL

	// 	ws.onopen = () => {
	// 		console.log('WebSocket connection opened');
			
	// 	};


	// 	ws.onmessage = (event) => {
	// 		debugger
	// 		setMessages((prevMessages) => [...prevMessages, event.data]);
	// 		showNotification(event.data); // Show notification on new message
	// 	};


	// 	ws.onclose = () => {
	// 		console.log('WebSocket connection closed');
	// 	};

	// 	// Cleanup the WebSocket connection when the component is unmounted
	// 	return () => {
	// 		ws.close();
	// 	};
	// }, []);

	
	  const toggleNotificationList = () => {
		setNotificationListVisible(!isNotificationListVisible);
	  };


	  const showNotification = (message) => {
		notification.open({
		  message: 'New Notification',
		  description: message,
		  placement: 'bottomRight', // Set placement to bottom right
		});
	  };

	  const menu = (
		<Menu>
		  <Menu.ItemGroup title="Notifications">
			{messages.map((message, index) => (
			  <Menu.Item key={index}>
				{message}
			  </Menu.Item>
			))}
		  </Menu.ItemGroup>
		</Menu>
	  );

	return (
		<nav className="navbar navbar-expand-lg bg-light" >
			<div className="container-fluid">
				<a className="navbar-brand"><img src={require("../assets/images/Company_logo.png")} height={"75px"}></img></a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav me-auto mb-2 mb-lg-0">
						<li className="nav-item dropdown">
							<a hidden={false} className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Activation</a>
							<ul className="dropdown-menu dropdown-menu-end" >
								<NavLink className="dropdown-item" to="/MemberShipplan">Buy Plan </NavLink>
							</ul>
						</li>
						<li className="nav-item dropdown">
							<a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Active Bot</a>
							<ul className="dropdown-menu dropdown-menu-end">
								<NavLink className="dropdown-item" to="/Apiactivaction">API Creation</NavLink>
								<NavLink className="dropdown-item" to="/Stargeyallocation">Strategy Apply</NavLink>
							</ul>
						</li>
						<li className="nav-item dropdown">
							<a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Tools</a>
							<ul className="dropdown-menu dropdown-menu-end">
								<NavLink className="dropdown-item" to="/Papertrading" hidden>PaperTrading</NavLink>
								<NavLink className="dropdown-item" to="/Mangment" hidden>Management</NavLink>
								<NavLink className="dropdown-item" to="/Trade">Trade by soft</NavLink>
								<NavLink className="dropdown-item" to="/Telegramsalert">Telegram Connect</NavLink>
							</ul>
						</li>
						<li className="nav-item dropdown">
							<a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Help</a>
							<ul className="dropdown-menu dropdown-menu-end">
								<NavLink className="dropdown-item" to="/Resorces">Resorces</NavLink>
								<NavLink className="dropdown-item" to="/Telegramchanel">Telegram channel</NavLink>
								<NavLink className="dropdown-item" to="/TelegramGroup">Telegram Group</NavLink>
								<NavLink className="dropdown-item" to="/Whatsapppage">Whatsapp</NavLink>
								<NavLink className="dropdown-item" to="/Videospage">Videos</NavLink>

							</ul>
						</li>
						<li className="nav-item dropdown" hidden={Auth != "Admin"}>
							<a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">AdminPanel</a>
							<ul className="dropdown-menu dropdown-menu-end" >
								<NavLink className="dropdown-item" to="/Admindashbord">Admin Dashboard</NavLink>
								<NavLink className="dropdown-item" to="/Clientlist">Client List</NavLink>
								<NavLink className="dropdown-item" to="/Clientplanstatragey">Client Plan & Strategy Details Group</NavLink>
								<NavLink className="dropdown-item" to="/Changeauth">ChangeAdmin Auth</NavLink>
								<NavLink className="dropdown-item" to="/UserInformation">UserInformation</NavLink>
								<NavLink className="dropdown-item" to="/Userinformationdetail" hidden>userinformationdetail</NavLink>
								<NavLink className="dropdown-item" to="/Tradingviewpost" >Tradingviewpost</NavLink>
								<NavLink className="dropdown-item" to="/Tradingviewlist">Exit from Trade</NavLink>
								<NavLink className="dropdown-item" to="/Convertdatas">Convert Data</NavLink>
							</ul>
						</li>
					</ul>
				</div>
				<div className='leftitems'>
					<nav className="navbar navbar-expand-lg navbar-light bg-light">
						<div className="container-fluid">
							<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon"></span>
							</button>
							<div className="collapse navbar-collapse" id="navbarSupportedContent">
								<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
									<li className="nav-item">
										<NavLink className="nav-link" to="/Dashbord">
											<HomeOutlined style={{ fontSize: '24px' }} />
										</NavLink>
									</li>
									<li className="nav-item dropdown">
										<a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
											<WalletOutlined style={{ fontSize: '24px' }} />
										</a>
										<ul className="dropdown-menu dropdown-menu-end">
											<li><a className="dropdown-item" href="#">Wallet: 0 INR</a></li>
											<li><NavLink className="dropdown-item" to="/WalletLog">Wallet Log</NavLink></li>
										</ul>
									</li>
									<li className="nav-item">
										<NavLink className="nav-link" to="#">
											<Badge count={messages.length} overflowCount={99} onClick={toggleNotificationList}>
												<BellOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
											</Badge>
										</NavLink>
										{isNotificationListVisible && (
											<div className="notificationPopup">
												<h5 className='py-3' style={{textAlign:"left",margin:"15px"}}>Notifaction</h5>
												<hr/>
												<ul className='messagesList'>
													{messages.map((message, index) => (
														<li key={index} className='messageItem'>{message}</li>
													))}
												</ul>
											</div>
										)}
									</li>
									<li className="nav-item">
										<span className="nav-link">
											{Username} <br />
											ID: {userId}
										</span>
									</li>
									<li className="nav-item dropdown">
										<a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
											<Avatar
												style={{
													backgroundColor: color,
													verticalAlign: 'middle',
												}}
												size="large">
												{user}
											</Avatar>
										</a>
										<ul className="dropdown-menu dropdown-menu-end">
											<li><NavLink className="dropdown-item" to="/ProfileEdit">Profile</NavLink></li>
											<li><NavLink className="dropdown-item" to="/Logout">Log Out</NavLink></li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</nav>
	)
}

export default Header