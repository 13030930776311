import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../store/actions/Index';
import Invoicepostservice from '../store/services/Invcpostser'
import Header from './Header'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';

const Checkout = () => {
    debugger

    const updatedonin = useSelector(state => state.updatedonin)
    const statrgychekoutreducer = useSelector(state => state.statrgychekoutreducer)

    const repayinvstatus = useSelector(state => state.repayinvstatus)
    const invcpost = useSelector(state => state.invcpost)
    let ckeoutamt = statrgychekoutreducer.totalamount
    const statrgylistsred = useSelector(state => state.statrgylistsred);
    const authState = useSelector(state => state.authState)
    const userId = localStorage.getItem('userid');
    const [ispaysucess, setIspaysucess] = useState(false)
    const memabeshipopt = useSelector(state => state.memabeshipopt)
    const dispatch = useDispatch();
    const stgtotal = statrgychekoutreducer.sttotal
    const granttotal = statrgychekoutreducer.totalamount
    let mdays = memabeshipopt.plandays
    let pursta = statrgychekoutreducer.slist
    const savedStrategyData = JSON.parse(localStorage.getItem('selectitmes'));
    const chekoutinvoiceid = localStorage.getItem('invoiceid')
    const chekoutinvoicetotal = localStorage.getItem('invoiceamount')
    debugger
    let cdata = savedStrategyData.map((value) => {
        debugger
        return (
            <tr key={value.straId}>
                <td>{value.straId}</td>
                <td>{value.straName}</td>
                <td>{value.straTotalamt}.00</td>
            </tr>
        )

    })


    const history = useHistory();

    const showinvoive = () => {
        let path = "/Invoice";
        history.push(path);
    }
    const cancelinvoice = () => {
        let path = "/Invoice";
        history.push(path);
    }
    const editinvoice = () => {
        let path = "/MemberShipplan";
        history.push(path);
    }
    return (
        <div style={{ marginTop: "100px" }}>

            {updatedonin.invid}
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="checkeoutsection p-4 shadow bg-white rounded">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h2 className="Cardheder">Checkout!</h2>
                                </div>
                            </div>
                            <div className="invoicehead col-md-12 my-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Your Order</h3>
                                        <h5>Invoice No: {chekoutinvoiceid}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="invoicehead col-md-12 my-3">
                                <h5>Membership Purchased</h5>
                                <table className="chekouttable table table-dark table-striped">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Type</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left" }}>{memabeshipopt.planid}</td>
                                            <td style={{ textAlign: "left" }}>{memabeshipopt.planname}</td>
                                            <td style={{ textAlign: "right" }}>{memabeshipopt.planPrice}.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="invoicehead col-md-12 my-3">
                                <h5>Strategy Purchased</h5>
                                <table className="table table-dark table-striped">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Type</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>{cdata}</tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Total:</td>
                                            <td></td>
                                            <td>{chekoutinvoicetotal}.00</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div className="actiondiv row my-4">
                                <div className="col-md-6 d-flex justify-content-start">
                                    <button type="button" onClick={showinvoive} className="btn btn-success mx-2">Pay Order</button>
                                    {/* <button type="button" onClick={cancelinvoice} className="btn btn-dark mx-2">Cancel</button> */}
                                    <button type="button" onClick={editinvoice} disabled={repayinvstatus.checkstatus === "false"} className="btn btn-dark mx-2">Edit Order</button>
                                </div>
                                <div className="invoicehead col-md-3">
                                    <h5>Total ₹ {chekoutinvoicetotal}/-</h5>
                                </div>
                                <div className="col-md-3 text-end">
                                    <p>Status: Not Started</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Checkout