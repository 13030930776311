export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL =' LOGOUT_FAIL';

export const REG_START = 'REG_START';
export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAIL = 'REG_FAIL';

export const APICREATE_START = 'APICREATE_START';
export const APICREATE_SUCCESS = 'APICREATE_SUCCESS';
export const APICREATE_FAIL = 'APICREATE_FAIL';

export const STATRGYAPPLY_START = 'STATRGYAPPLY_START'
export const STATRGYAPPLY_SUCCESS = 'STATRGYAPPLY_SUCCESS'
export const STATRGYAPPLY_FAIL = 'STATRGYAPPLY_FAIL'

export const USERPROFILEEDIT_START = 'USERPROFILEEDIT_START'
export const USERPROFILEEDIT_SUCCESS = 'USERPROFILEEDIT_SUCCESS'
export const USERPROFILEEDIT_FAIL = 'USERPROFILEEDIT_FAIL'

export const USERSOCIALEDIT_START = 'USERPROFILEEDIT_START'
export const USERSOCIALEDIT_SUCCESS = 'USERPROFILEEDIT_SUCCESS'
export const USERSOCIALEDIT_FAIL = 'USERPROFILEEDIT_FAIL'

export const USERCOMPANYEDIT_START = 'USERCOMPANYEDIT_START'
export const USERCOMPANYEDIT_SUCCESS = 'USERCOMPANYEDIT_SUCCESS'
export const USERCOMPANYEDIT_FAIL = 'USERCOMPANYEDIT_FAIL'

export const WALLATEBAL_START ='WALLATEBAL_START'
export const WALLATEBAL_SUCCESS ='WALLATEBAL_SUCCESS'
export const WALLATEBAL__FAIL = 'WALLATEBAL__FAIL'

export const WALLATELOG_START ='WALLATELOG_START'
export const WALLATELOG_SUCCESS ='WALLATELOG_SUCCESS'
export const WALLATELOG_FAIL = 'WALLATELOG__FAIL'

export const MEMBERSHIP_OPTAIN = "MEMBERSHIP_OPTAIN"

export const STATARGEY_OPTAIN = "STATARGEY_OPTAIN"

export const STATARGEY_LIST = "STATARGEY_LIST"

export const CHECK_EMAIL = "CHECK_EMAIL" 

export const CHECK_MOBILE = "CHECK_MOBILE" 

export const FTCH_USER = "FTCH_USER"

export const CREATE_OTP_MOBILE = "CREATE_OTP_MOBILE"

export const CREATE_OTP_EMAIL = "CREATE_OTP_EMAIL"

export const CHECK_OTP_MOBILE = "CHECK_OTP_MOBILE"

export const CHECK_OTP_EMAIL = "CHECK_OTP_EMAIL"

export const RESET_FORGOT_PASSWORD = "RESET_FORGOT_PASSWORD"

export const GET_MEMBERSHIP_LIST = "GET_MEMBERSHIP_LIST"

export const GET_STATARGY_LIST = "GET_STATARGY_LIST"

export const INVOICEPOST = "INVOICEPOST"

export const INVOICEPOST_SUCCESS = "INVOICEPOST_SUCCESS"

export const GET_OPTIAN_PLAN = "GET_OPTIAN_PLAN"

export const POST_RENEWAL_PLAN = "POST_RENEWAL_PLAN"

export const ADD_ON_STATAGEY_LIST = "ADD_ON_STATAGEY_LIST"

export const CHECK_STG_STATUS = "CHECK_STG_STATUS"

export const CHECK_STG_STATUS_ADDON = "CHECK_STG_STATUS_ADDON"

export const ADD_ON_STG_MEM = "ADD_ON_STG_MEM"

export const UPDATE_INVOICE = "UPDATE_INVOICE"

export const REPAYMENT_INVOICE = "REPAYMENT_INVOICE"

export const REPAYMENT_INVOICE_STATUS = "REPAYMENT_INVOICE_STATUS"

export const TELEGRAM_TOKEN_POST = "TELEGRAM_TOKEN_POST"

export const TELEGRAM_TOKEN_DELETE = "TELEGRAM_TOKEN_DELETE"

export const FETCH_TRADING_DATA = "FETCH_TRADING_DATA"

export const EXIT_USER_TRADING = "EXIT_USER_TRADING"

export const EXIT_STARAGEY_TRADING = "EXIT_STARAGEY_TRADING"

export const EXIT_SYAMOBL_TRADING = "EXIT_SYAMOBL_TRADING"

export const EXIT_DETIL_TRADING = "EXIT_DETIL_TRADING"

export const GET_USERALLINFORMATION = "GET_USERALLINFORMATION"

export const POST_TRADINGVIEWDATA = "POST_TRADINGVIEWDATA"
 

















