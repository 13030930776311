
import { CiSearch } from "react-icons/ci";
const Serchbox = () => {
    return (
        <div className="serchbox position-relative d-flex align-items-center" >
            <CiSearch/>
            <input type="text" placeholder="serch Heare...!"></input>
        </div>
    )
}

export default Serchbox