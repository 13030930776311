import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const MembershipExpiryPieChart = ({ daysRemaining = 90, totalDays=100 }) => {
  const [progress, setProgress] = useState(0);
  const percentage = (daysRemaining / totalDays) * 100;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        // If progress reaches the target percentage, stop the timer
        const diff = percentage - oldProgress;
        if (oldProgress >= percentage) {
          clearInterval(timer);
          return percentage;
        }
        return Math.min(oldProgress + 1, percentage); // Increment progress
      });
    }, 10); // Adjust speed of the animation

    return () => {
      clearInterval(timer); // Cleanup the interval on component unmount
    };
  }, [percentage]);

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={progress} // Use the animated progress state
        size={200}
        thickness={6}
       
        sx={{
          color: percentage < 20 ? '#ff5722' : '#4caf50', // Custom colors based on remaining days
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" component="div" color="textSecondary">
          {daysRemaining} days left
        </Typography>
      </Box>
    </Box>
  );
};

export default MembershipExpiryPieChart;
