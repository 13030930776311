import https from '../../utility/api-client'

class Getallusers
{
    
    getalluser(params)
    {
        return https.get('/User/GetUsers',params);
    }
}

export default new Getallusers();