import https from '../../utility/api-client'

class Createmobileotp
{
    
    creatmobotp(params)
    {
        return https.post('/OtpVerify/MobileOTP', params);
    }
}

export default new Createmobileotp();