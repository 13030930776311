import * as actiontype from '../actions/Actions';
import { mergObject } from '../../utility/util';
import { invoicepost,invoiceamount } from '../actions/auth';

const initialState = {
    isloding: false,
    error:null,
}

const invoicepostsuccess = (state, action) => {
    return mergObject(state, {
        invoiceid: action.invoiceid,
        invoiceamount: action.invoiceamount,
        error: null,
        isloading: false,
        isLoggedIn: true
    });
}



const reducer = (state=initialState,action) => 
{
    switch(action.type) {
      
        case actiontype.INVOICEPOST : return invoicepost(state,action)
        case actiontype.INVOICEPOST_SUCCESS : return invoicepostsuccess(state,action)
    
        default: return state;
    }
}

export default reducer;