import * as actiontype from '../actions/Actions';
import { mergObject } from '../../utility/util';
import { postrenuvalplan } from '../actions/Index'

const initialState = {
    error: null,
    isloding: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actiontype.POST_RENEWAL_PLAN: return postrenuvalplan(state, action)
        default: return state;
    }
}

export default reducer