import Header from "./Header"


const Telegramchanel = () => {
	
	return (
		
		<div>
			<Header/>
			<h1>Telegramchanel</h1>
		</div>

)

}

export default Telegramchanel