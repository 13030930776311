import React from 'react';
import { useEffect } from 'react';

const ImageScroller = () => {

    useEffect(() => {
        const scrollers = document.querySelectorAll(".scroller");
        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            debugger
            addAnimation();
        }

        function addAnimation() {
            scrollers.forEach((scroller) => {
                debugger
                // add data-animated="true" to every `.scroller` on the page
                scroller.setAttribute("data-animated", true);
                debugger
                // Make an array from the elements within `.scroller-inner`
                const scrollerInner = scroller.querySelector(".scroller__inner");
                const scrollerContent = Array.from(scrollerInner.children);
                // For each item in the array, clone it
                // add aria-hidden to it
                // add it into the `.scroller-inner`
                scrollerContent.forEach((item) => {
                    const duplicatedItem = item.cloneNode(true);
                    duplicatedItem.setAttribute("aria-hidden", true);
                    scrollerInner.appendChild(duplicatedItem);
                });
            });
        }

    }, [])
    return (
        <div class="scroller" data-direction="right" data-speed="slow">
            <div class="scroller__inner">
                <img src={require("../assets/images/Fyers.png")} height={130} alt={"workingpartner"} />
                <img src={require("../assets/images/MetaTrader5-removebg.png")} height={130} alt={"workingpartner"} />
                <img src={require("../assets/images/CMSPrime-removebg.png")} height={130} alt={"workingpartner"} />
                <img src={require("../assets/images/Fyers.png")} height={130} alt={"workingpartner"} />
                <img src={require("../assets/images/MetaTrader5-removebg.png")} height={130} alt={"workingpartner"} />
                <img src={require("../assets/images/CMSPrime-removebg.png")} height={130} alt={"workingpartner"} />
            </div>
        </div>
    );
};

export default ImageScroller;