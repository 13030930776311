import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    checkstatus:false,
    error: null,
    isloding: false,
}

const repayementinvoicestatus = (state, action) => {
    return mergObject(state, {
        checkstatus:true,
        error: null,
        isloading: false,
    });
}

const reducer = (state=initialState,action) => 
{

    switch(action.type) 
    {
        case actiontype.REPAYMENT_INVOICE_STATUS: return repayementinvoicestatus(state,action)
  
        default : return state;
    }
}

export default reducer