
import https from '../../utility/api-client'

class Orderservices
{
    
    orderser(userid)
    {
        return https.get('/Fyers/GetTradeLog?UserId='+userid);
    }
}

export default new Orderservices();