import { Form, InputNumber, Popconfirm, Table, Typography, Input, Select, Space, Button } from 'antd';
import { useEffect } from 'react';
import { useState, useRef } from 'react';
import AlluserList from '../store/services/alluserList'
import { ToastContainer, toast } from 'react-toastify';
import { SearchOutlined } from '@mui/icons-material';
import Highlighter from 'react-highlight-words';
import Header from '../component/Header';
import Loaidngcom from "../component/loadingcomponent"
import Userchaneauth from "../store/services/userauthchang"
import { values } from 'lodash';
// const originData = [];
// for (let i = 0; i < 100; i++) {
//   originData.push({
//     key: i.toString(),
//     username: `Edward ${i}`,
//     mobile: 98900400600,
//     email: `London Park no. ${i}`,
//     authority:"Admin"
//   });
// }

const App = () => {
    debugger
    const [form] = Form.useForm();
    const [data, setData] = useState("");
    const [editingKey, setEditingKey] = useState("");
    const [authstatus, setAuthsatus] = useState("")
    const [Athoritytype, setAthoritytype] = useState([
        {
            text: 'Admin',
            value: 'Admin',
        },
        {
            text: 'Sub Admin',
            value: 'Sub Admin ',
        },
        {
            text: 'Member',
            value: 'Member',
        }])
    const [activaction, setAectivection] = useState([
        {
            text: "Software activaction",
            values: "Software activaction"
        },
        {
            text: "Investment activaction",
            values: "Investment activaction"
        },

    ])



    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        debugger
        clearFilters();
        setSearchText('');

    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>

                    <Button

                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })


    useEffect(() => {

        debugger

        const params = null;
        AlluserList.getalluser(params).then((apires) => {
            <Loaidngcom />
            debugger
            setData(apires.data.data)

        })
            .catch((error) => {
                console.log("faild")
                debugger
            })

    }, [])

    const isEditing = (record) => record.id === editingKey;


    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {


        const inputNode = inputType === 'number' ? <InputNumber /> : <Select >
            <option value={"Admin"}></option>
            <option value={"Sub Admin"}></option>
            <option value={"Member"}></option>

        </Select>;
        const activactions = inputType === 'number' ? <InputNumber /> : <Select >
            <option value={"Yes"}></option>
            <option value={"No"}></option>

        </Select>;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {title == "Athority" ? inputNode : activactions}

                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };


    const edit = (record) => {
        debugger
        form.setFieldsValue({
            name: '',
            mobile: '',
            address: '',
            authority: '',

            ...record,
        });
        debugger
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (id) => {
        debugger
        let usertype = null
        let sotacv = null
        let invacv = null
        let userid = id
        debugger
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,

                });

                usertype = row.authStatus
                sotacv = row.softActive
                invacv = row.invtActive


                console.log()
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }


        debugger
        let params = { Id: userid, authStatus: usertype, SoftActive: sotacv, InvtActive: invacv }
        Userchaneauth.chauth(params).then((apiRes) => {
            debugger
            toast.success("Update Record Successfully ", {
                position: "top-center"
            })

        })
            .catch((error) => {
                debugger
                console.log("faild")
                toast.error("Faild to update Staus ", {
                    position: "top-center"
                })
            });



    };

    debugger
    const columns = [

        {
            title: 'User id',
            dataIndex: 'id',
            width: '10%',
            sorter: (a, b) => a.id - b.id,


        },
        {

            title: 'User Name',
            dataIndex: 'profileName',
            width: '15%',
            sorter: (a, b) => a.profileName.length - b.profileName.length,
            ...getColumnSearchProps('profileName'),
        },

        {
            title: 'Email',
            dataIndex: 'email',
            width: '15%',
            sorter: (a, b) => a.profileName.length - b.profileName.length,
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Mobile',
            dataIndex: 'mobileNumber',
            width: '10%',
            ...getColumnSearchProps('mobileNumber'),

        },
        {
            title: 'Athority',
            dataIndex: 'authStatus',
            width: '12%',
            editable: true,
            filters: Athoritytype,
            onFilter: (value, record) => record.authStatus.indexOf(value) === 0,
            align: "center"
        },
        {
            title: 'SoftwareActivaction',
            dataIndex: 'softActive',
            width: '12%',
            editable: true,
            filters: activaction,
            onFilter: (value, record) => record.activaction.indexOf(value) === 0,
            align: "center"
        },
        {
            title: 'InvestmentActivaction',
            dataIndex: 'invtActive',
            width: '12%',
            editable: true,
            filters: activaction,
            onFilter: (value, record) => record.activaction.indexOf(value) === 0,
            align: "center"
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            width: '12%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },


    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'mobile' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (

        <div style={{marginTop:"100px"}}>
            <Form form={form} component={false}>
                <div className='authstatuschne'>
                    <h5></h5>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },

                        }}
                        bordered
                        dataSource={data}
                        columns={mergedColumns}
                        scroll={{ x: true }}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    />
                </div>

                <ToastContainer
                    position="top-left"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Form>
        </div>

    );

};
export default App;