import Header from "./Header"
import { Form, Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from "react";
import { useDispatch, useSelector, us } from 'react-redux';
import { Card } from "react-bootstrap";
import { Redirect, useHistory } from 'react-router-dom'
import * as actions from '../store/actions/Index'
import Statargeyservives from "../store/services/Userservices"
import Addonstatrgeylistser from "../store/services/newstglistforaddon"
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fa0 } from "@fortawesome/free-solid-svg-icons";
import Invoicepostservice from '../store/services/Invcpostser'
const Statargeyplan = () => {
    
    const updatedonin = useSelector(state => state.updatedonin)
    const authState = useSelector(state => state.authState)
    const srgchkstutesed = useSelector(state => state.srgchkstutesed)
    const memabeshipopt = useSelector(state => state.memabeshipopt);
    const statrgychekoutreducer = useSelector(state => state.statrgychekoutreducer);
    const dispatch = useDispatch();
    const [ispaysucess, setIspaysucess] = useState(false)
    const [cardlist, setCardlist] = useState([])
    const [addstg, setAddstg] = useState(true)
    const [staratgeylist, setStaratgeylist] = useState();
    const userId = localStorage.getItem('userid')

    // let memamt = memabeshipopt.planPrice
    // let mdays = memabeshipopt.plandays
    


    let memamt = localStorage.getItem('planPrice')
    let mdays = localStorage.getItem('planDays')

    const formik = useFormik({
        initialValues: {
            Statatgylist: "",
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            Statatgylist: Yup.boolean()
                .oneOf([true], "Accept Terms and Condition")
                .required("Shoud Agree Terms and condition"),
        }),

    })

    // const cardlist = Statargeyservives.Getstargelist()

    const [selectitmes, setSelecteditmes] = useState([])
    const [selectitmesid, setSelecteditmesid] = useState([])
    const [selecttotal, setSelecttotal] = useState()
    const [stglist, setStglist] = useState({})



    useEffect(() => {
      
        if (srgchkstutesed.isaddon == false) {
          
            dispatch(actions.getmemberstatrageylist())
            let params = null
            Statargeyservives.Getstargelist(params).then((apiRes) => {
            
                setCardlist(apiRes.data.data)

            })
                .catch((error) => {
                    console.log("faild")
                

                })
        } else {

           
            dispatch(actions.addonstatargeylist())
            let userid = userId
            let invid = 0

            Addonstatrgeylistser.addstglist(userid, invid).then((apiRes) => {
            
                setCardlist(apiRes.data.data)

            })
                .catch((error) => {
                    console.log("faild")
                  

                })

        }
    }, [])

    let tot = 0
    const addstargey = (e, stargey) => {
    
        let newselectitmes = [...selectitmes]
     
        if (e.target.checked) {

            // newselectitmes.push({ id: stargey.straId, stgname: stargey.straName, stgtotal: (stargey.straTotalamt * mdays) })
            // let stamt = straAmount
            // let plandays = mdays
            // let stgdiscount = straDisc
            let total = (stargey.straAmount * mdays * stargey.straDisc) / 100
            let stgamo = (stargey.straAmount * mdays) - total
            stgamo = Math.round(stgamo)
            newselectitmes.push({ straId: stargey.straId, straName: stargey.straName, straTotalamt: stgamo })
            setSelecttotal(stargey.straTotalamt)
        }
        else {
            const index = newselectitmes.findIndex((currentvalue, index) => {

                return stargey.straId == currentvalue.straId
            });

            if (index != null && index != undefined && index > -1) {
                newselectitmes.splice(index, 1)
            }
        }
      
        setSelecteditmes(newselectitmes)
        console.log(selectitmes)

    }

    let cards = cardlist.map((item) => {
        return (
            <div className="col-md-4 mb-3 mb-sm-0">
                <div key={item.straId} className="card">
                    <div className="card-body">
                        <h5>{item.straName}</h5>
                        <p>{item.stgtime}</p>
                        <h5>{item.straProduct}</h5>
                        <h6>{item.straAmount}/- Rs</h6>
                        <h6 className="Discountamount" style={{ color: "#ff5722", fontWeight: "bold", marginBottom: "10px" }}>{item.straDisc} % Discount</h6>
                        <h5>{item.straTotalamt}/- Rs</h5>
                        <h6>With Ratio {item.straRatio}</h6>
                        <label>
                            <h6 className="mb-3">Minimum investment {item.straInv}</h6>
                            <input type="checkbox" value="999" onClick={(e) => { addstargey(e, item) }} />
                            <span>Select</span>
                        </label>
                    </div>
                </div>
            </div>
        );
    });


    let cdata = selectitmes.map((value) => {
    
        return (
            <tr key={value.straId}>
                <td style={{ width: "50px" }} scope="row">{value.straId}</td>
                <td style={{ width: "10px" }}>{value.straName}</td>
                <td style={{textAlign:"right"}}>{value.straTotalamt}/-</td>
            </tr>
        )

    })

    let total = 0;
    let toatalamaount = 0;
    selectitmes.forEach(itm => {
        total = parseInt(total) + parseInt(itm.straTotalamt)
    })
    let subtotal = parseInt(total) + parseInt(memamt)


    const history = useHistory();

    const invoicepost = () => {
        
        let userid = userId
        let membershipplnaiid = localStorage.getItem('planId')

        if (membershipplnaiid == null) {
            let path = "/MemberShipplan";
            history.push(path);
        }
        
        let pursta = selectitmes
        if (pursta.length > 0) {
            let cdata = pursta.map((value) => {
                
    
                return (
                    { "StraId": value.straId }
                )
    
            })
            
            let invoicedata =
            {
                InvId: 0,// it will pending for updating invoice 
                UserId: userid,
                PlanId: membershipplnaiid,
                Det: cdata
            }
    
            dispatch(actions.invoicepost())
            
            Invoicepostservice.invoiceposting(invoicedata).then((apiRes) => {
                
                // dispatch(actions.emailotpcheck(apiRes));
                console.log(invoicedata)
                if (apiRes.data.isSuccess) {
                    
                    let invoiceid = apiRes.data.data.invId
                    let invoiceamount = apiRes.data.data.invTotAmt
                    dispatch(actions.invoicepostsuccess(invoiceid, invoiceamount))
                    localStorage.setItem('invoiceid', invoiceid)
                    localStorage.setItem('invoiceamount', invoiceamount)
                    setIspaysucess(true)
                    let path = "/Checkout";
                    history.push(path);
                    setStglist(selectitmes)
                    
                    dispatch(actions.satageyoptain(subtotal, selectitmes, total))
                    console.log(selectitmes)
                    
                    localStorage.setItem('selectitmes', JSON.stringify(selectitmes));
                    toast.info("Request Sumbmited sucessfully", {
                        position: "top-center"
                    })
                } else {
                    toast.error("Faild To process!", {
                        position: "top-center"
                    })
    
                }
            })
                .catch((error) => {
                    console.log("faild")
                    console.log(invoicedata)
                    
    
                });
        }
    }



    const chekoutpage = () => {
        
        if (cdata.length !== 0) {
            let path = "/Checkout";
            history.push(path);
            setStglist(selectitmes)
            dispatch(actions.satageyoptain(subtotal, selectitmes, total))
        } else {
            toast.error("Select at least one Statragey", {
                position: "top-center"
            })

        }

    }

    return (
        <div>


            <h3>Select Your Stargey </h3>
            <p>Tips:-Statargey Amount will Be calulate on Membership Days </p>
            <div className="container-fluid">
                <form onSubmit={formik.submitForm}>
                    <div className="statragycart">
                        <div className="row">
                            <div className="cartlist col-md-7">
                                <div className="row">
                                    {cards}
                                </div>

                            </div>
                            <div className="cartlistselect col-md-5">
                                <div className="col-md-12 mb-3 mb-sm-0">
                                    <div className="billdetial">
                                        <h5>Selected Strategy</h5>
                                        <table className="table table-success">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Strategy</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cdata}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th style={{ backgroundColor: "white" }}>Total</th>
                                                    <td style={{ backgroundColor: "white"}}></td>
                                                    <td className="totalamt" style={{ backgroundColor: "white", color: "black"   }}>₹ {total}.00</td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <button type="button" style={{ width: "100%" }} className="btn btn-info" onClick={invoicepost}><i
                                            class="bi bi-cart2" ></i>Check out</button>
                                        <div className="billdicripation">
                                            <h6>Strategy:<span>₹ {total}.00</span></h6>
                                            <h6>Membership:<span>₹ {memamt}.00</span></h6>
                                            <h6>Total:<span>₹ {subtotal}.00</span></h6>
                                        </div>

                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"

            />
        </div>
    )
}

export default Statargeyplan    