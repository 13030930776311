import { useEffect } from "react"
import Dashtotalrefrel from "../store/services/Dashtotalrefrel"
import { useState } from "react"
import { FaUserCircle } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoMdTrendingUp } from "react-icons/io";

const Apiworkingcard = () => {

    const [dashreferlcount, setdashreferlcount] = useState(1223)


    useEffect(() => {
        let userId = localStorage.getItem('userid');
        Dashtotalrefrel.gettotalref(userId).then((apires) => {
            setdashreferlcount(apires.data.data)

        })
            .catch((error) => {
                console.log("Total refrel faild")
            })
    }, [])

    return (

            <div className="dashbordbox" style={{ backgroundImage: 'linear-gradient(to right,#2c78e5, #60aff5)', position: 'relative' }}>
                <div className="d-flex w-100">
                    <div className="col1">
                        <h4 className="text-white mb-0">Total Referral</h4>
                        <span className="text-white">{dashreferlcount}</span>
                    </div>
                    <div className="icon-container">
                        <span className="icon"><FaUserCircle /></span>
                    </div>
                </div>
                <span className="continerbackground"><IoMdTrendingUp /></span>
            </div>
        
    )

}

export default Apiworkingcard