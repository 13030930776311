import https from '../../utility/api-client'

class Telegramtokenpost
{
    
    telegramtoken(params)
    {
        return https.post('/Telegram/PostTelegramToken', params);
    }
}

export default new Telegramtokenpost();