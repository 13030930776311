import React from 'react';
import { Container, Card, CardContent, Typography, Divider } from '@mui/material';

const CancellationRefund = () => {
  return (
    <div style={{marginTop:"120px"}}>
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Card elevation={3} sx={{ borderRadius: '16px', padding: 3 }}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
            Cancellation and Refund Policy
          </Typography>

          <Divider sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
            Cancellation Policy
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            You can cancel your membership plan at any time by visiting your account settings. Cancellation requests must be made at least 24 hours before the renewal date to avoid being charged for the next billing cycle.
          </Typography>

          <Divider sx={{ mt: 3, mb: 3 }} />
          <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
            Refund Policy
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            Refunds are only available within 7 days of the initial purchase, provided that you have not used any membership benefits. After the 7-day period, no refunds will be issued. 
          </Typography>

          <Divider sx={{ mt: 4 }} />
          <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 3 }}>
            For further inquiries about cancellations or refunds, please contact our support team.
          </Typography>
        </CardContent>
      </Card>
    </Container>
    </div>

  );
};

export default CancellationRefund;
