import Statargeyservives from "../store/services/Userservices"
import { useDispatch, useSelector, us } from 'react-redux';
import * as actions from '../store/actions/Index'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Invoicepag = () => {
	debugger
	const invcpost = useSelector(state => state.invcpost);
	const statrgychekoutreducer = useSelector(state => state.statrgychekoutreducer);
	const memabeshipopt = useSelector(state => state.memabeshipopt)
	const dispatch = useDispatch();
	const savedStrategyData = JSON.parse(localStorage.getItem('selectitmes'));
	const stgtotal = localStorage.getItem('invoiceamount')
	const granttotal = localStorage.getItem('invoiceamount')
	const chekoutinvoiceid = localStorage.getItem('invoiceid')
	// let pursta = statrgychekoutreducer.slist
	let cdata = savedStrategyData.map((value) => {
		return (
			<tr key={value.straId}>
				<td>{value.straId}</td>
				<td>{value.straName}</td>
				<td>{value.straTotalamt}.00</td>
			</tr>
		)

	})

    const history = useHistory()
    const showinvoive = () => {   
		let path = "/Dashboard";
		history.push(path);
}

	return (

		<div className="container">

			<div className="Invoice">
				<div className="Invoicehead col-md-12">
					<h1>Invoice</h1>
				</div>
				<div className="Invoicedate col-md-12">
					<h4>Invoice No:{chekoutinvoiceid}</h4>
					<p>Invoice Date:20-12-2022</p>
				</div>
				<div className="Invoiceto row">
					<div className="col-md-6">
						<p>Invoice To:-administrator</p>
						<p>User Name:- administrator</p>
						<p>E-Mail:-administrator@gmail.com</p>
					</div>
					<div className="col-md-6">
						<p>Payment Details:</p>
						<p>Total Due:{granttotal}/-</p>
						<p>E-Mail:Cashfree</p>
					</div>
				</div>
				<div className="Invoicetital row">
					<div className="col-md-6">
						<h5>Membership purchased </h5>
						<table className="table table-dark table-striped">
							<thead>
								<tr>
									<th>Id</th>
									<th>Type</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{memabeshipopt.planid}</td>
									<td>{memabeshipopt.planname}</td>
									<td>{memabeshipopt.planamount}</td>
								</tr>
							</tbody>
						</table>

						<h5>Statagey purchased</h5>
						<table className="table table-dark table-striped">
							<thead>
								<tr>
									<th>Id</th>
									<th>Type</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								{cdata}
							</tbody>
							<tfoot>
								<tr>
									<td>Total:</td>
									<td></td>
									<td>{stgtotal}.00</td>
								</tr>
							</tfoot>
						</table>

					</div>
					<div className="col-md-6">
						<table className="table">
							<thead>
								<tr>
									<th scope="col">Particuler</th>
									<th scope="col">Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Plan Subtotal:</th>
									<td>{granttotal}.00</td>
								</tr>
								<tr>
									<th>Discount:</th>
									<td>000.00</td>
								</tr>
								<tr>
									<th>Sub Total:</th>
									<td>000.00</td>
								</tr>
								<tr>
									<th>Other Charges:</th>
									<td>000.00</td>
								</tr>
								<tr>
									<th>Total:</th>
									<td>000.00</td>
								</tr>
								<tr>
									<th>GST 18% :</th>
									<td>000.00</td>
								</tr>
								<tr>
									<th>Grand Total:</th>
									<td>{granttotal}.00</td>
								</tr>
							</tbody>
						</table>
						<button type="button" className="btn btn-primary" onClick={showinvoive} >Close</button>
					</div>
				</div>
				<div className="Invoicetitaltow row">
				</div>
			</div>
		</div>
	)


}


export default Invoicepag