import https from '../../utility/api-client'

class Tradestatusonoff
{
    
    tradeonoff(userid,fid,tsatus,formtag)
    {
        return https.get('Fyers/SetTradeStatus?UserId='+userid+'&fyersId='+fid+'&TradeStatus='+tsatus+'&formtag='+formtag);
    }
}

export default new Tradestatusonoff();