import https from '../../utility/api-client'

class MetaPostdesibalservices
{
    
    metaPostdesibalservices(params)
    {
        return https.post('Fyers/PostFidDisable', params);
    }
}

export default new MetaPostdesibalservices();