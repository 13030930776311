import Header from "./Header"
const Mangment = () => {

	return (
		<div>
			<form>
				<div className="container-flauid" style={{marginTop:"150px"}}>
					<div className="mangement">
						<div className="row">
							<div>
								<div className="managements">
									<h5> Management</h5>
								</div>
								<div className="col-md-2 mb-3 mt-3">
									<label htmlFor="NoofTrade">No of Trade</label>
									<input type="text" id="HeaderPart No of Trade" className="form-control" name="HeaderPart No of Trade" />
								</div>
								<div className="col-md-2 mb-3 mt-3">
									<button className="btn btn-primary">Click Heare</button>
								</div>
							</div>
						</div>
					</div>
					<div className="DetailPart">
						<table className="table">
							<thead>
								<tr>
									<th scope="col">Sr.No.</th>
									<th scope="col">Created</th>
									<th scope="col">IT</th>
									<th scope="col">Symbol</th>
									<th scope="col">Exchange</th>
									<th scope="col">Product</th>
									<th scope="col">ReqID</th>
									<th scope="col">LTP</th>
									<th scope="col">Adj01</th>
									<th scope="col">Adj02</th>
									<th scope="col">Boost01</th>
									<th scope="col">Boost02</th>
									<th scope="col">Target01</th>
									<th scope="col">Target02</th>
									<th scope="col">Exit</th>
									<th scope="col">Status</th>
									<th scope="col">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">1</th>
									<td><input type="text" id="txtcreat" name="txtcreat" size={15} className="form-control" /></td>
									<td><input type="text" id="txtit" name="txtit" size={15} className="form-control" /></td>
									<td><input type="text" id="txtSymbol" name="txtSymbol" size={15} className="form-control" /></td>
									<td><input type="text" id="txtExchange" name="txtExchange" size={15} className="form-control" /></td>
									<td><input type="text" id="txtProduct" name="txtProduct" size={15} className="form-control" /></td>
									<td><input type="text" id="txtreqid" name="txtreqid" size={15} className="form-control" /></td>
									<td><input type="text" id="txtltp" name="txtltp" size={15} className="form-control" /></td>
									<td><input type="text" id="txtAdjust1" name="txtAdjust1" size={15} className="form-control" /></td>
									<td><input type="text" id="txtadjust2" name="txtadjust2" size={15} className="form-control" /></td>
									<td><input type="text" id="txtBoost 1" name="txtBoost" size={15} className="form-control" /></td>
									<td><input type="text" id="txtBoost2" name="txtBoost2" size={15} className="form-control" /></td>
									<td><input type="text" id="txtTarget" name="txtTarget" size={15} className="form-control" /></td>
									<td><input type="text" id="txtTarget2" name="txtTarget2" size={15} className="form-control" /></td>
									<td><input type="text" id="txtExit" name="txtExit" size={15} className="form-control" /></td>
									<td><input type="text" id="txtStatus" name="txtStatus" size={15} className="form-control" /></td>
									<td><input type="text" id="txtAction" name="txtAction" size={15} className="form-control" /></td>
								</tr>
							</tbody>
							<tfoot>
							</tfoot>
						</table>
					</div>
				</div>
			</form>

		</div>
	)
}


export default Mangment