import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Paper
} from '@mui/material';

const ContactUs = () => {
  return (
    <div style={{marginTop:"120px"}}> 
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Card elevation={3} sx={{ borderRadius: '16px', padding: 3 }}>
          <CardContent>
            {/* Main Title */}
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              Contact Us
            </Typography>

            {/* Subtitle */}
            <Typography variant="body1" paragraph sx={{ color: '#555', textAlign: 'center', mb: 4 }}>
              Have any questions? We'd love to hear from you. Fill out the form below and we'll get in touch as soon as possible.
            </Typography>

            {/* Form and Contact Information Section */}
            <Grid container spacing={3}>
              {/* Form Section */}
              <Grid item xs={12} sm={6}>
                <Grid container spacing={3} direction="column">
                  {/* Name Field */}
                  <Grid item xs={12}>
                    <TextField
                      label="Name"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>

                  {/* Email Field */}
                  <Grid item xs={12}>
                    <TextField
                      label="Email"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>

                  {/* Subject Field */}
                  <Grid item xs={12}>
                    <TextField
                      label="Subject"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>

                  {/* Message Field */}
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
                    <Button variant="contained" color="primary" size="large">
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/* Contact Information Section */}
              <Grid item xs={12} sm={6}>
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Contact Information
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                    Email: contact@yourwebsite.com
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                    Phone: +123 456 7890
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                    Address: 123 Your Street, Your City, Your Country
                  </Typography>
                </CardContent>

              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default ContactUs;
