import https from '../../utility/api-client'

class TelegrammsgAuth
{
    
    telegrammsgauth(paramas)
    {
        return https.post('/Telegram/PostTelegramMsgAuth',paramas);
    }
}

export default new TelegrammsgAuth();