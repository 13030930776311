import https from '../../utility/api-client'

class Meta5activesatus
{
    
    meta5active(uid)
    {
        return https.get('/MetaTrader/GetMtActiveAPI?user_id= '+uid);
    }
}

export default new Meta5activesatus();