import https from '../../utility/api-client'

class Checkmobile
{
    
    chkmobile(mobileno)
    {
        return https.get('/User/GetMobile?CountryCode=91&MobileNumber='+ mobileno);
    }
}

export default new Checkmobile();