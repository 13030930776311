import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';
import {forgotpassreset} from '../actions/Index'

const initialState = {
    error: null,
    isloding: false,
}


const reducer = (state=initialState,action) => 
{
    switch(action.type) 
    {
      
        case actiontype.RESET_FORGOT_PASSWORD : return forgotpassreset(state,action)
      
        default : return state;
    }
}

export default reducer