import { useState } from "react"
import { useEffect } from "react"
import Dashwhatsnew from "../store/services/Dashwhatsnew"
const Whatsnew = () => {
const [whatsnewdata,setWhatsnewdata] = useState([])

useEffect(()=>{
    let params = null
    Dashwhatsnew.dashwhatnew(params).then((apires) => {
        debugger
        setWhatsnewdata(apires.data.data)
    })
        .catch((error) => {
            console.log("faild")
            debugger
        })

},[])

    // const whatsnewdata = [
    //     {id:"1",uapdationtital:"0",version:"0",upadationdetail:"0",dttime:""},
    //     {id:"2",uapdationtital:"0",version:"0",upadationdetail:"0",dttime:"15-01-2022 12.22"},
    //     {id:"32",uapdationtital:"0",version:"0",upadationdetail:"0",dttime:"15-01-2022 12.22"}
    // ]

    let whdata = whatsnewdata.map((values)=>{
        return (
            <div key={values.wnId} className="list-group" >
                <a className="list-group-item list-group-item-action " aria-current="true">
                    <div className="d-flex w-100 justify-content-between">
                        <h6 className="mb-1"><b>{values.uapdationtital}</b>:<span>Log Version{values.wnVersion}</span></h6>
                        <small>{values.wnDate}</small>
                    </div>
                    <p className="mb-1">Updation Details:{values.wnDesc}</p>
                </a>
            </div>
        )
    })

    return (
        <div className="whatnewlist">
                {whdata}
        </div>
    )
}

export default Whatsnew