import https from '../../utility/api-client'

class Combofilluser
{
    
    fillusers(params)
    {
        return https.get('/Admin/GetComboUsers',params);
    }
}

export default new Combofilluser();