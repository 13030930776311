import https from '../../utility/api-client'

class FayersAPIlist
{
    
    fayersacetivelist(uid,fid)
    {
        return https.get('/Fyers/GetFList?user_id='+uid+'&fid='+fid);
    }
}

export default new FayersAPIlist();