import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';
import {usercompanyeditstart} from '../actions/Index'

const initialState = {
    error: null,
    isloding: false,
}


const reducer = (state=initialState,action) => 
{
    switch(action.type) 
    {
        case actiontype.USERCOMPANYEDIT_START : return mergObject(state,{error:null,isloding:true})
        case actiontype.USERCOMPANYEDIT_SUCCESS : return usercompanyeditstart(state,action)
        case actiontype.USERCOMPANYEDIT_FAIL: return mergObject(state,{error:action.error,isloding:false})
        default : return state;
    }
}

export default reducer 