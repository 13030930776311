import https from "../../utility/api-client";
class Mmermershipplanservives
{
    GetMembershiplist(params)
    {
        return https.get('/App/GetPlans',params)
    }
}

export default new Mmermershipplanservives();



// [
//     { id: "1", cardtype: "SILVER", carddays: "30", cardamount: "500", carddiscount: "100", cardtotal: "400" },
//     { id: "2", cardtype: "GOLD", carddays: "90", cardamount: "1500", carddiscount: "500", cardtotal: "1000" },
//     { id: "3", cardtype: "PALTINUM", carddays: "180", cardamount: "3000", carddiscount: "800", cardtotal: "2200" },
//     { id: "4", cardtype: "DIAMOND", carddays: "365", cardamount: "6000", carddiscount: "1800", cardtotal: "4200" },
// ]