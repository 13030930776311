import { type } from '@testing-library/user-event/dist/type'
import * as actiontype from './Actions'

export const loginStart= () => {return {type:actiontype.LOGIN_START}}
export const loginSuccess= (token,userid,username,auth) => {return {type:actiontype.LOGIN_SUCCESS,token,userid,username,auth}}
export const loginFail= (error) => {return {type:actiontype.LOGIN_FAIL,error}}

export const logoutStart = () => {return {type:actiontype.LOGOUT_START}}
export const logoutSuccess = () => {return {type:actiontype.LOGOUT_SUCCESS}}
export const logoutFail = (error) => {return {type:actiontype.LOGOUT_FAIL,error}}

export const registrationStart = () => {return {type:actiontype.REG_START}}
export const registrationSuccess = () => {return {type:actiontype.REG_SUCCESS}}
export const registartionFaild = (error) => {return{type:actiontype.REG_FAIL},error}

export const apicreatestart = () => {return{type:actiontype.APICREATE_START}}
export const apicreatesuccess = () =>  {return{type:actiontype.APICREATE_SUCCESS}}
export const apicreatefail = (error) => {return{type:actiontype.APICREATE_FAIL},error}

export const statrgyapplystart = () => {return{type:actiontype.STATRGYAPPLY_START}}
export const statrgyapplysuccess = () => {return{type:actiontype.STATRGYAPPLY_SUCCESS}}
export const statrgeyapplyfail = (error) => {return{type:actiontype.STATRGYAPPLY_FAIL,error}}

export const userprofileeditstart = () => {return{type:actiontype.USERPROFILEEDIT_START}}
export const userprofileeditsuceess = () => {return{type:actiontype.USERPROFILEEDIT_SUCCESS}}
export const userprofileeditfail = () => {return{type:actiontype.USERPROFILEEDIT_FAIL}}

export const usersocialeditstart = () => {return{type:actiontype.USERSOCIALEDIT_START}}
export const usersocialeditsuccess = () => {return{type:actiontype.USERPROFILEEDIT_SUCCESS}}
export const usersocialeditfail = () => {return{type:actiontype.USERSOCIALEDIT_FAIL}}


export const usercompanyeditstart = () => {return{type:actiontype.USERCOMPANYEDIT_START}}
export const usercompanyeditsuccess = () => {return{type:actiontype.USERCOMPANYEDIT_SUCCESS}}
export const usercompanyeditstartfail = () => {return{type:actiontype.USERCOMPANYEDIT_FAIL}}

export const wallatebalstart = () => {return{type:actiontype.WALLATEBAL_START}}
export const wallatebalsuccess = () => {return{type:actiontype.WALLATEBAL_SUCCESS}}
export const wallatebalfail = () => {return{type:actiontype.WALLATEBAL__FAIL}}


export const wallatelogstart = () => {return{type:actiontype.WALLATELOG_START}}
export const wallatelogsuccess = () => {return{type:actiontype.WALLATELOG_SUCCESS}}
export const wallatelogfail = () => {return{type:actiontype.WALLATELOG_FAIL}}

export const membershipoptain = (planid,planPrice,planname,plandays) => {return{type:actiontype.MEMBERSHIP_OPTAIN,planid,planPrice,planname,plandays}}

export const satageyoptain = (totalamount,slist,sttotal) => {return{type:actiontype.STATARGEY_OPTAIN,totalamount,slist,sttotal}}

 export const getstatrgeylist = (slist) => {return{type:actiontype.STATARGEY_LIST,slist}}

 export const checkemail = (useremail) => {return{type:actiontype.CHECK_EMAIL}}

 export const checkmobile = (usermobile) => {return{type:actiontype.CHECK_MOBILE}}

 export const fetchuser = (userid) => {return{type:actiontype.FTCH_USER,userid}}

export const mobileotpsend = () => {return {type:actiontype.CREATE_OTP_MOBILE}}

export const emailotpsend = () => {return{type:actiontype.CREATE_OTP_EMAIL}}    

export const mobileotpcheck = () => {return {type:actiontype.CHECK_OTP_MOBILE}}

export const emailotpcheck = () => {return{type:actiontype.CHECK_OTP_EMAIL}}  

export const forgotpassreset = () => {return{type:actiontype.RESET_FORGOT_PASSWORD}} 

export const getmemershipplanlist = () => {return{type:actiontype.GET_MEMBERSHIP_LIST}}

export const getmemberstatrageylist = () => {return{type:actiontype.GET_STATARGY_LIST}}

export const invoicepost = () => {return{type:actiontype.INVOICEPOST}}

export const invoicepostsuccess = (invoiceid,invoiceamount) => {return{type:actiontype.INVOICEPOST_SUCCESS,invoiceid,invoiceamount}}

export const getoptainplan = (userid) => {return{type:actiontype.GET_OPTIAN_PLAN,userid}}

export const postrenuvalplan = () => {return{type:actiontype.POST_RENEWAL_PLAN}}

export const addonstatargeylist = () => {return{type:actiontype.ADD_ON_STATAGEY_LIST}}

export const chekstgsttaues = () => {return{type:actiontype.CHECK_STG_STATUS}}

export const chekstgsttauesaddon = () => {return{type:actiontype.CHECK_STG_STATUS_ADDON}}

export const addonstgmem = () => {return{type:actiontype.ADD_ON_STG_MEM}}

export const  updateinvoice = (invid) => {return{type:actiontype.UPDATE_INVOICE,invid}}

export const  repayementinvoice = () => {return{type:actiontype.REPAYMENT_INVOICE}}

export const  repayementinvoicestatus = () => {return{type:actiontype.REPAYMENT_INVOICE_STATUS}}

export const  telegramtoken = () => {return{type:actiontype.TELEGRAM_TOKEN_POST}}

export const  telegramtokendelete = () => {return{type:actiontype.TELEGRAM_TOKEN_DELETE}}

export const  fetchtradingdata = () => {return{type:actiontype.FETCH_TRADING_DATA}}

export const  exitusertrading = () => {return{type:actiontype.EXIT_USER_TRADING}}

export const  exitstatageytrading = () => {return{type:actiontype.EXIT_STARAGEY_TRADING}}

export const  exitsymboltrading = () => {return{type:actiontype.EXIT_SYAMOBL_TRADING}}

export const  exitdetailtrading = () => {return{type:actiontype.EXIT_DETIL_TRADING}}

export const  userallinformation = (userid) => {return{type:actiontype.GET_USERALLINFORMATION,userid}}

export const  posttradingtada = () => {return{type:actiontype.POST_TRADINGVIEWDATA}}








