import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    stglist: null,
    error: null,
    isloding: false,
}


const addonstatargeylist = (state, action) => {
    return mergObject(state, {
        newstglist: action.stglist,
        error: null,
        isloading: false,

    });
}




const reducer = (state=initialState,action) => 
{

    switch(action.type) 
    {
        case actiontype.ADD_ON_STATAGEY_LIST : return addonstatargeylist(state,action)
  
        default : return state;
    }
}

export default reducer