import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';



const initialState = {
    isaddon :false,
    error: null,
    isloading: false,
    isLoggedIn: false
}


const chekstgsttaues = (state, action) => {
    return mergObject(state, {
        isaddon :false,
        error: null,
        isloading: false,
        isLoggedIn: false
    });
}

const chekstgsttauesaddon = (state, action) => {
    return mergObject(state, {
        isaddon :true,
        error: null,
        isloading: false,
        isLoggedIn: false
    });
}


const reducer = (state=initialState,action) => 
{
    switch (action.type) {   
        case actiontype.CHECK_STG_STATUS:return chekstgsttaues(state, action) 
        case actiontype.CHECK_STG_STATUS_ADDON:return chekstgsttauesaddon(state, action) 
     
        default: return state;
    }    
}

export default reducer;