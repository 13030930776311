import Userlogs from "../store/services/Userlogs"
import { useEffect,useState } from 'react';
import {LoginOutlined,LogoutOutlined} from '@ant-design/icons'
const Loginhistory = () => {
	const [userlogdata,setUserlogdata] = useState([])
	const [remaindays, setRemaindays] = useState(75)
	const [plandays, setPlandays] = useState(100)
	const [dayspersent, Setdayspersent] = useState("")
	const [progrsscolure, setprogrsscolure] = useState("")
    useEffect(() => {
		let userid = localStorage.getItem('userid');
		let logfrom ="Soft"
		Userlogs.userlogs(userid,logfrom).then((apires) => {
		
			setUserlogdata(apires.data.data)

		})
			.catch((error) => {
				console.log("faild")
			
			})

	}, [])
    
	let userlog= userlogdata.map((ueserlo)=>{
		return (
			<ul>
				<li>Loged On:- {ueserlo.logDateTime}</li>
			</ul>
		)

	})
    return (
        <div>
               <div className="userloges">
                {userlog}
                </div>     
        </div>
    )
}

export default Loginhistory