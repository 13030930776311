import https from "../../utility/api-client";

class Remaningdyassoft
{
    remaningdayssoft(userid,Invt)
    {
        return https.get('Inv/GetInvtRemainDays?UserId='+userid+'&InvFrom='+Invt)
    }
}
export default new Remaningdyassoft();
