import https from '../../utility/api-client'

class Checkmobileotp
{
    
    chkmobileotp(mobileno,otpnum)
    {
        return https.get('/OtpVerify/MobileOTPVerify?MobileCode=+91&MobileNum='+mobileno+'&OTP='+otpnum);
    }
}

export default new Checkmobileotp();