import https from "../../utility/api-client";

class Statrgeyapply 
{
    staapply (params)
    {
        return https.post('Straallow/PostStraAllow',params)
    }
}

export default new Statrgeyapply();