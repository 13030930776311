
import https from '../../utility/api-client'

class Gettotalrefrel
{
    
    gettotalref(uesrid)
    {
        return https.get('/User/GetUserTotRefC?UserId='+uesrid);
    }
}

export default new Gettotalrefrel();