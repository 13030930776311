import https from '../../utility/api-client'

class Fyersactivestatus
{
    
    fyersactive(uid)
    {
        return https.get('/Fyers/GetFActiveAPI?user_id= '+uid);
    }
}

export default new Fyersactivestatus();