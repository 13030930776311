

import https from "../../utility/api-client";

class Dashwhatsnew
{
    dashwhatnew(params)
    {
        return https.get('/Admin/GetWhatsNew',params);
    }
}

export default new Dashwhatsnew();
