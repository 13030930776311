import { useState } from "react"
import {Switch,Button,Popconfirm,Table} from "antd"
const Allapiactive = () => {
    const [allapidata,setallapidata] = useState([
        {Brokername:"Mt5",fyersid:"1",Appaname:"Bwe123",Action:"Active/Inactive" },
        {Brokername:"Mt4",fyersid:"2",Appaname:"Bwe13",Action:"Active/Inactive" }
    ])

    const modifydata = allapidata.map(({ ...item }) => ({
        ...item,
        key: item.mtId,
    }))

    const colums = [
        {
            title: "MetaId",
            dataIndex: "mtId",
            align: "center",
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => a.metafiveID - b.metafiveID,
                multiple: 1,
            }
        },
        // {
        //     title: "ClientId",
        //     dataIndex: "clientId",
        //     align: "center",

        // },
        {
            title: "AppName",
            dataIndex: "appName",
            align: "center"
        },
        {
            title: "Active/Inactive",
            dataIndex: "tradeStatus",
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Switch checkedChildren="Active" unCheckedChildren="Inactive"
                            checked={(record.tradeStatus == "ok") ? true : false}
                            // onChange={() => Meta5inactiveapi(record)}
                            // onChange={(e) => {UpdateSt(e, record, "tradeStatus") }}
                        />
                    </>
                )
            }

        },




    ]
    return (
        <>
            <div className="allapis">
            <Table
                    columns={colums}
                    dataSource={modifydata}
                    bordered
                    scroll={{ x: true}}
                />

            </div>
        </>
    )
}

export default Allapiactive