import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';
import { mobileotpcheck,emailotpcheck } from '../actions/auth';


const initialState = {
    error: null,
    isloding: false,
}


const reducer = (state=initialState,action) => 
{
    switch(action.type) 
    {
        case actiontype.CHECK_OTP_MOBILE: return mobileotpcheck(state,action)
        case actiontype.CHECK_OTP_EMAIL : return emailotpcheck(state,action)
        default : return state;
    }
}

export default reducer 