// ConfirmToast.js
import React from 'react';

const ConfirmToast = ({ onConfirm, onCancel }) => (
  <div className="confirm-toast">
    <p>Are you sure you want to delete this record?</p>
    <div>
      <button className="yes" onClick={onConfirm}>Yes</button>
      <button className="no" onClick={onCancel}>No</button>
    </div>
  </div>
);

export default ConfirmToast;
