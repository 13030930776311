import https from "../../utility/api-client";

class Apideletion
{
    apidelete(params)
    {
        return https.post('/Fyers/PostFDel',params);
    }
}

export default new Apideletion();
