import { combineReducers } from "redux";
import authReducer from "./auth";
import registrationredu from "./regstration";
import apicaration from "./apicreation"
import statargey from "./stargeyappy"
import userprofileditred from "./userprofileedit"
import usersoceditreu from "./userSocialedit"
import usercomnayeditredu from "./usercomnayedit"
import walllatebalred from "./Wallatebal"
import wallatelogredu from "./Wallatelog"
import membershippal from "./user"
import statrgychekout from "./Statartgeychekout"
import chekemailredu from "./checkmail"
import userfetchredu from "./userfetchreducer"
import createotp from "./createotp"
import chkotp from "./checkotp"
import forgotrestpass from "./forgotrestpass"
import fetchmemberlist from "./fetchmemberlist"
import Invpost from "./Invpost"
import getpurchedmbership from "./getpurchedmbership"
import addonstglistreducer from "./addonstglistreducer"
import srgchkstutes from "./srgchkstutes"
import updatein from "./updatein"
import Renewalplan from "./Renewalplan"
import Repayinvoice from "./Repaymentinvoice"
import Repaymentinvoicestatus from "./repaymentinvoicestatus"
import Telegramtokenpost from "./telegramtokenpost"
import UserInfoid from "./userinformationall";
import Posttrading from "./posttrading"

export default combineReducers({
    authState:authReducer,
    registration:registrationredu,
    apicreate:apicaration,
    statargeyapl:statargey,
    userprofiledit:userprofileditred,
    usersociedit:usersoceditreu,
    usercomedit:usercomnayeditredu,
    walllatebal:walllatebalred,
    wallatelog:wallatelogredu,
    memabeshipopt:membershippal,
    statrgychekoutreducer:statrgychekout,
    chekuseremail:chekemailredu,
    userfetcred:userfetchredu,
    creatingotp:createotp,
    chkingotp:chkotp,
    resetforgotpass:forgotrestpass,
    fetchmlist:fetchmemberlist,
    invcpost:Invpost,
    getmemberoptainplan:getpurchedmbership,
    addonsthlistred:addonstglistreducer,
    srgchkstutesed:srgchkstutes,
    updatedonin:updatein,
    renewusermembershipplan:Renewalplan,
    repayinv:Repayinvoice,
    repayinvstatus:Repaymentinvoicestatus,
    telegrmtokenpost:Telegramtokenpost,
    userinfoid:UserInfoid,
    posttrading:Posttrading


})