import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    userid: null,
    error: null,
    isloading: false,
    isLoggedIn: false
}


const userinfosuccess = (state, action) => {
    return mergObject(state, {
        userid: action.userid,
        error: null,
        isloading: false,
        isLoggedIn: true
    });
}


const reducer = (state=initialState,action) => 
{
    switch (action.type) {
        case actiontype.GET_USERALLINFORMATION:return userinfosuccess(state, action)

 
        default: return state;
    }    
}

export default reducer;