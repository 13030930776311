import https from '../../utility/api-client'

class Combofillticker
{
    
    filltiker(params)
    {
        return https.get('/Admin/GetComboTicker',params);
    }
}

export default new Combofillticker();