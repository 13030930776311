import { useState } from "react"
import Header from "../component/Header"
// import Cousrmerlisttable from "../Admincomponent/Clientlisttable"
import { Form, Formik, Field, ErrorMessage, useFormik, validateYupSchema } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import react, { useEffect } from "react";
import _ from "lodash";
import https from "../Admincomponent/pagination"

const listofclient = [
	{ id: "1", name: "zzzz", mobileno: "5555555555", email: "abc@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-04-2022", RemainingDays: "15" },
	{ id: "2", name: "zzzz", mobileno: "4444444444", email: "xyz@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-05-2022", RemainingDays: "36" },
	{ id: "3", name: "zzzz", mobileno: "3333333333", email: "ass@gmail.com,", active: "Active", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-06-2022", RemainingDays: "25" },
	{ id: "4", name: "zzzz", mobileno: "2222222222", email: "sads@gmail.com", active: "Inactive", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-07-2022", RemainingDays: "40" },
	{ id: "5", name: "zzzz", mobileno: "1111111111", email: "asdasdasd@gmail.com", active: "Active", Planid: "1", PlanName: "Plantinum", PlanActiveDate: "01-08-2022", RemainingDays: "65" },
	{ id: "6", name: "zzzz", mobileno: "5555555555", email: "abc@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-04-2022", RemainingDays: "15" },
	{ id: "7", name: "zzzz", mobileno: "4444444444", email: "xyz@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-05-2022", RemainingDays: "36" },
	{ id: "8", name: "zzzz", mobileno: "3333333333", email: "ass@gmail.com,", active: "Active", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-06-2022", RemainingDays: "25" },
	{ id: "9", name: "zzzz", mobileno: "2222222222", email: "sads@gmail.com", active: "Inactive", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-07-2022", RemainingDays: "40" },
	{ id: "10", name: "zzzz", mobileno: "1111111111", email: "asdasdasd@gmail.com", active: "Active", Planid: "1", PlanName: "Plantinum", PlanActiveDate: "01-08-2022", RemainingDays: "65" },
	{ id: "11", name: "zzzz", mobileno: "5555555555", email: "abc@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-04-2022", RemainingDays: "15" },
	{ id: "12", name: "zzzz", mobileno: "4444444444", email: "xyz@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-05-2022", RemainingDays: "36" },
	{ id: "13", name: "zzzz", mobileno: "3333333333", email: "ass@gmail.com,", active: "Active", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-06-2022", RemainingDays: "25" },
	{ id: "14", name: "zzzz", mobileno: "2222222222", email: "sads@gmail.com", active: "Inactive", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-07-2022", RemainingDays: "40" },
	{ id: "15", name: "zzzz", mobileno: "1111111111", email: "zzzz@gmail.com", active: "Active", Planid: "1", PlanName: "Plantinum", PlanActiveDate: "01-08-2022", RemainingDays: "65" },
	{ id: "16", name: "yyyy", mobileno: "5555555555", email: "yyyy@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-04-2022", RemainingDays: "15" },
	{ id: "17", name: "xxxx", mobileno: "4444444444", email: "xxxx@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-05-2022", RemainingDays: "36" },
	{ id: "18", name: "wwww", mobileno: "3333333333", email: "wwww@gmail.com,", active: "Active", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-06-2022", RemainingDays: "25" },
	{ id: "19", name: "vvvv", mobileno: "2222222222", email: "vvvv@gmail.com", active: "Inactive", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-07-2022", RemainingDays: "40" },
	{ id: "20", name: "uuuu", mobileno: "1111111111", email: "uuuu@gmail.com", active: "Active", Planid: "1", PlanName: "Plantinum", PlanActiveDate: "01-08-2022", RemainingDays: "65" },
	{ id: "21", name: "tttt", mobileno: "5555555555", email: "tttt@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-04-2022", RemainingDays: "15" },
	{ id: "22", name: "ssss", mobileno: "4444444444", email: "xyz@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-05-2022", RemainingDays: "36" },
	{ id: "23", name: "rrrr", mobileno: "3333333333", email: "ssss@gmail.com,", active: "Active", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-06-2022", RemainingDays: "25" },
	{ id: "24", name: "qqqq", mobileno: "2222222222", email: "rrrr@gmail.com", active: "Inactive", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-07-2022", RemainingDays: "40" },
	{ id: "25", name: "pppp", mobileno: "1111111111", email: "qqqq@gmail.com", active: "Active", Planid: "1", PlanName: "Plantinum", PlanActiveDate: "01-08-2022", RemainingDays: "65" },
	{ id: "26", name: "oooo", mobileno: "5555555555", email: "pppp@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-04-2022", RemainingDays: "15" },
	{ id: "27", name: "nnnn", mobileno: "4444444444", email: "nnnn@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-05-2022", RemainingDays: "36" },
	{ id: "28", name: "mmmm", mobileno: "3333333333", email: "mmmm@gmail.com,", active: "Active", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-06-2022", RemainingDays: "25" },
	{ id: "29", name: "llll", mobileno: "2222222222", email: "llll@gmail.com", active: "Inactive", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-07-2022", RemainingDays: "40" },
	{ id: "30", name: "kkkk", mobileno: "1111111111", email: "kkkk@gmail.com", active: "Active", Planid: "1", PlanName: "Plantinum", PlanActiveDate: "01-08-2022", RemainingDays: "65" },
	{ id: "31", name: "jjjj", mobileno: "5555555555", email: "jjjj@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-04-2022", RemainingDays: "15" },
	{ id: "33", name: "iiii", mobileno: "4444444444", email: "iiii@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-05-2022", RemainingDays: "36" },
	{ id: "35", name: "hhhh", mobileno: "3333333333", email: "hhhh@gmail.com,", active: "Active", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-06-2022", RemainingDays: "25" },
	{ id: "36", name: "gggg", mobileno: "2222222222", email: "gggg@gmail.com", active: "Inactive", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-07-2022", RemainingDays: "40" },
	{ id: "37", name: "ffff", mobileno: "1111111111", email: "ffff@gmail.com", active: "Active", Planid: "1", PlanName: "Plantinum", PlanActiveDate: "01-08-2022", RemainingDays: "65" },
	{ id: "38", name: "eeee", mobileno: "5555555555", email: "eeee@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-04-2022", RemainingDays: "15" },
	{ id: "39", name: "dddd", mobileno: "4444444444", email: "dddd@gmail.com", active: "Active", Planid: "1", PlanName: "Silver", PlanActiveDate: "01-05-2022", RemainingDays: "36" },
	{ id: "40", name: "cccc", mobileno: "3333333333", email: "cccc@gmail.com,", active: "Active", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-06-2022", RemainingDays: "25" },
	{ id: "41", name: "bbbb", mobileno: "2222222222", email: "bbbb@gmail.com", active: "Inactive", Planid: "1", PlanName: "Gold", PlanActiveDate: "01-07-2022", RemainingDays: "40" },
	{ id: "42", name: "aaaa", mobileno: "1111111111", email: "aaaa@gmail.com", active: "Active", Planid: "1", PlanName: "Plantinum", PlanActiveDate: "01-08-2022", RemainingDays: "65" },
]

let cliet = listofclient.map((values) => {
	return (
		<tr>
			<th key={values.id}>{values.id}</th>
			<td>{values.name}</td>
			<td>{values.mobileno}</td>
			<td>{values.email}</td>
			<td>{values.active}</td>
			<td>{values.Planid}</td>
			<td>{values.PlanName}</td>
			<td>{values.PlanActiveDate}</td>
			<td>{values.RemainingDays}</td>
			<td>{values.status}</td>
		</tr>
	)

})

const pageSize = 10;
const Clientlist = () => {
	const formik = useFormik({
		initialValues: {
			custid: "",
			custname: "",
			remfrom: "",
			remto: ""
		},
		validateOnMount: true,
		validationSchema: Yup.object({
		}),
		onSubmit: (values) => {
			console.log(values)
		}

	})


	const [data, setData] = useState(listofclient)
	const [serchitems, setSearchitme] = useState("")
	const [order, setOrder] = useState("ASC")
	const [currentpage, setCurrentpage] = useState(1)
	const [postperpage, setPostperpage] = useState(10)
	const lastpostindex = currentpage * postperpage
	const firstpostindex = lastpostindex - postperpage
	const currentpost = data.slice(firstpostindex, lastpostindex);

	const sorting = (col) => {
		debugger
		if (order === "ASC") {
			const sorted = [...listofclient].sort((a, b) =>
				a.id.toLowerCase() > b.name.toLowerCase() ? 1 : -1
			);
			setData(sorted)
			setOrder("DEC")
		}
		if (order === "DEC") {
			const sorted = [...listofclient].sort((a, b) =>
				a.id.toLowerCase() < b.name.toLowerCase() ? 1 : -1
			);
			setData(sorted)
			setOrder("ASC")
		}
	}

	const sortingbyname = (col) => {
		if (order === "ASC") {
			const sorted = [...listofclient].sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
			);
			setData(sorted)
			setOrder("DEC")
		}
		if (order === "DEC") {
			const sorted = [...listofclient].sort((a, b) =>
				a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
			);
			setData(sorted)
			setOrder("ASC")
		}
	}

	return (
		<div>
			<div className="container">
				<div className="accordion" id="accordionExample">
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingOne">
							<button  className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
								Click Heare! 
							</button>
						</h2>
						<div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
							<div className="accordion-body">
								<div className="serchbox">
									<div className="row">
										<div className="col-md-2" style={{ marginBottom: 15, marginTop: 15 }}>
											<label>ID</label>
										</div>
										<div className="col-md-3">
											<input type="text" name="custid" id="custid" className="form-control"
												onChange={formik.handleChange}
												value={formik.values.custid}
												onBlur={formik.handleBlur}></input>
										</div>
										<div className="col-md-7">
										</div>
									</div>
									<div className="row">
										<div className="col-md-2" style={{ marginBottom: 15, marginTop: 15 }}>
											<label>Name</label>
										</div>
										<div className="col-md-3">
											<input type="text" name="custname" id="custname" className="form-control"
												onChange={formik.handleChange}
												value={formik.values.custname}
												onBlur={formik.handleBlur}></input>
										</div>
										<div className="col-md-7">
										</div>
									</div>
									<div className="row">
										<div className="col-md-2" style={{ marginBottom: 15, marginTop: 15 }}>
											<label>Active/Inactive</label>
										</div>
										<div className="col-md-3">
											<select className="form-select" aria-label="Default select example" style={{ marginBottom: 5, marginTop: 5 }}>
												<option value="1">Active</option>
												<option value="2">Inactive</option>
											</select>
										</div>
										<div className="col-md-7">
										</div>
									</div>
									<div className="row">
										<div className="col-md-2" style={{ marginBottom: 15, marginTop: 15 }}>
											<label>Active Date from:</label>
										</div>
										<div className="col-md-2">
											<input type="date" className="form-control" ></input>
										</div>
										<div className="col-md-2">
											<input type="date" className="form-control"></input>
										</div>
									</div>
									<div className="row">
										<div className="col-md-2" style={{ marginBottom: 15, marginTop: 15 }}>
											<label>Remaning Days</label>
										</div>
										<div className="col-md-2">
											<input type="text" className="form-control" name="remfrom" id="remfrom"
												onChange={formik.handleChange}
												value={formik.values.remfrom}
												onBlur={formik.handleBlur}></input>
										</div>
										<div className="col-md-2">
											<input type="text" className="form-control" name="remto" id="remto"
												onChange={formik.handleChange}
												value={formik.values.remto}
												onBlur={formik.handleBlur}></input>
										</div>
									</div>
									<div className="row">
										<div className="col-md-2" style={{ marginBottom: 15, marginTop: 15 }}>
											<button type="button" className="btn btn-primary">Clicl Heare </button>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				<div className="clientlist">
					<h4>Client List</h4>
					<table className="table">
						<thead className="table-dark">
							<tr>
								<th onClick={sorting}>Id</th>
								<th onClick={sortingbyname}>Name</th>
								<th >Mobileno</th>
								<th >Email</th>
								<th>Active</th>
								<th >Planid</th>
								<th>PlanName</th>
								<th>PlanActiveDate</th>
								<th>RemainingDays</th>

							</tr>
						</thead>
						<tbody>
							{currentpost.map((values, index) => {
								return (
									<tr key={index}>
										<th >{values.id}</th>
										<td>{values.name}</td>
										<td>{values.mobileno}</td>
										<td>{values.email}</td>
										<td>{values.active}</td>
										<td>{values.Planid}</td>
										<td>{values.PlanName}</td>
										<td>{values.PlanActiveDate}</td>
										<td>{values.RemainingDays}</td>
										<td>{values.status}</td>
									</tr>
								)
							})}

						</tbody>
					</table>
					<nav>
						<ul>
							<https totalpost={data.length} postperpage={postperpage} setCurrentpage={setCurrentpage} />
						</ul>
					</nav>
				</div>
			</div>
		</div>
	)
}

export default Clientlist

