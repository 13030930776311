import { Form, Formik, Field, ErrorMessage, useFormik, validateYupSchema } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import * as actions from '../store/actions/Index';
import regservices from '../store/services/regservices';
import checkmail from '../store/services/checkmail'
import checkmobile from '../store/services/checkmobile';
import createmobileotp from '../store/services/createmobileotp'
import checkotp from '../store/services/checkmobileotp'
import createemailotp from '../store/services/createemailotp'
import checkemailotp from '../store/services/checkemailotp'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { useState } from 'react';
import { values } from 'lodash';
import { Input } from 'antd'
const Signup = () => {
    const [user, setUser] = useState("")
    const registration = useSelector(state => state.registration);
    const checkmailservices = useSelector(state => state.checkmailservices);
    const sendmobileservices = useSelector(state => state.sendmobileservices);

    const [useremail, setUseremail] = useState("")
    const [usermobile, setUsermobile] = useState("")
    const [mobileotp, setMobileotp] = useState("")
    const [otpmsg, setOtpmsg] = useState("")
    const [useremailotp, setEmailotp] = useState("")
    const [sendotpmobile, setSendotpmobile] = useState(false)
    const [sendotpemail, setSendotpemail] = useState(false)
    const [otp, setOtp] = useState("");

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30)
    const [runing, setRuning] = useState(false)


    const [minutesmobile, setMinutesmobile] = useState(0);
    const [secondsmobile, setSecondsmobile] = useState(30)
    const [runingmobile, setRuningmobile] = useState(false)

    const [resendmobileotp, setResendmobileotp] = useState(false)
    const [resendemaileotp, setResendemaileotp] = useState(false)

    const [valiedemail, setValiedemail] = useState(false)
    const [valiedmobile, setValiedmobile] = useState(false)

    const [valiedemailbyotp, setValiedemailbyotp] = useState(false)
    const [valiedmobilebyotp, setvaliedmobilebyotp] = useState(false)

    const dispatch = useDispatch();
    const history = useHistory()
    const [stapereg, setstapereg] = useState(1)
    const regExp = /[a-zA-Z0-9.%+-]+@[a-z0-9-]+\.[a-z]{2,8}(.[a-z{2,8}])?/;
    const regExpmob = /^[0-9]{10}$/;
    const massage = ""
     
    useEffect(() => {
        if (runing) {
             
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);

            };
        }
    });


    useEffect(() => {
        if (runingmobile) {
             
            const interval = setInterval(() => {
                if (secondsmobile > 0) {
                    setSecondsmobile(secondsmobile - 1);
                }

                if (secondsmobile === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSecondsmobile(59);
                        setMinutesmobile(minutesmobile - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    });


    useEffect(() => {

    }, [valiedmobile])


    useEffect(() => {

    }, [valiedemail])

    const formik = useFormik({
        initialValues: {
            username: "",
            Email: "",
            password: "",
            confirmpassword: "",
            conterycode: "91",
            phone: "",
            iagree: "",
            referalcode: "",
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            username: Yup.string()
                .required("User Name is Requierd"),
            password: Yup.string()
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:")
                .required("Plase Enter Password"),
            confirmpassword: Yup.string()
                .oneOf([Yup.ref('password')], "Password Not Match")
                .required("Plase Enter confirmpassword"),
            // conterycode: Yup.number()
            //     .required("Required"),
            // phone: Yup.string()
            //     .max(10, "The number Shold Have Ten Digsit")
            //     // .typeError("Enter Valid Phone Number")
            //     .required("Must Enter Mobile No"),
            iagree: Yup.boolean()
                .oneOf([true], "Accept Terms and Condition")
                .required("Shoud Agree Terms and condition"),
        }),

        onSubmit: values => {
            // console.log("form data", values)
            setUser(values)
            console.log(user)
        },
    })



    const regster = () => {
         

        const username = formik.values.username
        const email = useremail
        const password = formik.values.password
        const conrtcode = formik.values.conterycode
        const userphone = usermobile
        const agree = formik.values.iagree
        const refcode = formik.values.referalcode
        dispatch(actions.registrationStart());
        const params = { Id: "0", ProfileName: username, Email: email, CountryCode: conrtcode, MobileNumber: userphone, Password: password, ReferalCode: refcode, postal_add: "0", pin_code: "0", twitter: "0", facebook: "0", instagram: "0", company_details: "0", gst_numnber: "0", user_for: "Soft" };
         
        regservices.reg(params).then((apiRes) => {
             
            // dispatch(actions.registrationStart(apiRes));
            console.log(apiRes)

            let uid = apiRes.data.data.id
            let uname = apiRes.data.data.profileName
            // dispatch(actions.loginSuccess(0, uid, uname));
             
            let path = "/SignIn";
            history.push(path);
        })
            .catch((error) => {
                // dispatch(actions.(error));
                 
                console.log("faild")

            });
    }


    const chekemailvadie = (elementValue) => {
         
        const email = useremail
    }

    const chekemail = () => {
         
        const email = useremail
        if (regExp.test(email)) {
            setValiedemail(true)
        } else {
            setValiedemail(false)
        }

        if (valiedemail) {
            dispatch(actions.checkemail());
            checkmail.chkmemail(email).then((apiRes) => {
                dispatch(actions.checkemail(apiRes));
                console.log(apiRes)
                 
                if (apiRes.data.data.email !== "") {
                    toast.error("Email is Already Exists", {
                        position: "top-center"
                    })
                    setSendotpemail(true)
                }
                else {
                    setSendotpemail(false)
                }

            })
                .catch((error) => {
                    dispatch(actions.checkemail(error));
                    console.log("faild")
                });
        }
        else {
            toast.error("Plese enter valied email", {
                position: "top-center"
            })
            setSendotpemail(false)
        }
    }

    const chekmobile = () => {
         
        // const params = formik.values.conterycode  + '' + usermobile
        const mobileno = usermobile
        if (regExpmob.test(mobileno)) {
            setValiedmobile(true)
        } else {
            setValiedmobile(false)
        }
        if (valiedmobile) {
             
            dispatch(actions.checkmobile())
            checkmobile.chkmobile(mobileno).then((apiRes) => {
                dispatch(actions.checkmobile(apiRes));
                console.log(apiRes)
                 
                if (apiRes.data.data.mobileNumber !== "") {
                    toast.error("Mobile Already Exists", {
                        position: "top-center"
                    })
                    setSendotpmobile(true)
                } else {
                    setSendotpmobile(false)
                }
            })
                .catch((error) => {
                    dispatch(actions.checkmobile(error));
                    console.log("faild")
                });
        }
        else {
             
            toast.error("Plese enter valied Mobileno", {
                position: "top-center"
            })
        }
    }



    const resendOTP = () => {
        setMinutes(1);
        setSeconds(30);
    };

    // const postmobileotp = () => {

    //      
    //     const email = useremail
    //     // const conrtcode = formik.values.conterycode
    //     const userphone = usermobile
    //     dispatch(actions.mobileotpsend());
    //     const params = { ContryCode: "+91", MobileNum: usermobile };
    //      

    //     if (valiedmobile == true) {
    //         createmobileotp.creatmobotp(params).then((apiRes) => {
    //              
    //             dispatch(actions.mobileotpsend(apiRes));
    //             console.log(apiRes)
    //              
    //             setSendotpmobile(apiRes.data.data.mobileotp)
    //              
    //             toast.success(apiRes.data.data.mobileotp, {
    //                 position: "top-center"
    //             })
    //             setSendotpmobile(true)
    //             setRuningmobile(true)
    //             setMinutesmobile(0);
    //             setSecondsmobile(30);

    //         })
    //             .catch((error) => {
    //                 console.log("faild")
    //                  
    //             });

    //     } else {
    //         toast.info("Plese Enter Valied mobile no", {
    //             position: "top-center"
    //         })

    //     }

    // }



    // const ckmobileotp = () => {
    //      
    //     const email = useremail
    //     // const conrtcode = formik.values.conterycode
    //     // const userphone = usermobile
    //     const mobileno = usermobile
    //     const otpnum = mobileotp

    //     dispatch(actions.mobileotpcheck());
    //     const params = { ContryCode: "+91", MobileNum: usermobile };
    //      

    //     checkotp.chkmobileotp(mobileno, otpnum).then((apiRes) => {
    //          
    //         dispatch(actions.mobileotpcheck(apiRes));
    //         console.log(apiRes)
    //          
    //         setOtpmsg(apiRes.data.message)

    //         if (apiRes.data.data.mobileotp !== "") {
    //             toast.info(apiRes.data.message, {
    //                 position: "top-center"
    //             })
    //             setvaliedmobilebyotp(true)
    //             setResendmobileotp(false)
    //             setMinutesmobile(0);
    //             setSecondsmobile(0);

    //         } else {
    //             toast.warn(apiRes.data.message, {
    //                 position: "top-center"
    //             })
    //             setvaliedmobilebyotp(false)
    //             setResendmobileotp(true)
    //             setMinutesmobile(0);
    //             setSecondsmobile(0);

    //         }
    //     })
    //         .catch((error) => {
    //             console.log("faild")
    //              

    //         });
    // }


    const postemailotp = () => {

         
        const email = useremail
        // const conrtcode = formik.values.conterycode
        const userphone = usermobile
        dispatch(actions.emailotpsend());
        const params = { Email: useremail };
         

        if (valiedemail == true) {
            createemailotp.creemailotp(params).then((apiRes) => {
                 
                dispatch(actions.emailotpsend(apiRes));
                console.log(apiRes.data.data.emailotp)
                setEmailotp(apiRes.data.data.emailotp)
                 
                toast.success(apiRes.data.data.emailotp, {
                    position: "top-center"
                })
                setResendemaileotp(true)
                setSendotpemail(true)
                setRuning(true)
                setMinutes(0);
                setSeconds(30);
                setstapereg(2)

            })
                .catch((error) => {
                    console.log("faild")
                     
                });
        } else {

            toast.error("Plese enter valied email", {
                position: "top-center"
            })
             
            setSendotpemail(false)
        }

    }


    const ckemailotp = () => {
         
        const email = useremail
        const otpnum = useremailotp
        dispatch(actions.emailotpcheck());
         
        checkemailotp.chkmobileotp(email, otpnum).then((apiRes) => {
             
            dispatch(actions.emailotpcheck(apiRes));
            console.log(apiRes)
             
            setOtpmsg(apiRes.data.message)

            if (apiRes.data.data.emailotp !== "") {
                toast.info(apiRes.data.message, {
                    position: "top-center"
                })
                setValiedemailbyotp(true)
                setResendemaileotp(false)
                setMinutes(0);
                setSeconds(0);
                setRuning(false)
                setstapereg(3)

            } else {
                toast.warn(apiRes.data.message, {
                    position: "top-center"
                })
                setValiedemailbyotp(false)  
                setResendemaileotp(true)
                setMinutes(0);
                setSeconds(0);

            }

        })
            .catch((error) => {
                console.log("faild")
                 

            });
    }



    return (

        <div className="container">
            {sendotpemail}
            <div className="row registercontainner col-md-12">
                <div className="register col-md-4">
                    <div>
                        <img src={require("../assets/images/comapny.png")} height={100}></img>
                        <h1>Sign up</h1>
                        <h5>Please sign-up to Start your account</h5>
                        <form onSubmit={formik.submitForm}>


                            {stapereg === 1 && (
                                <div className='first_stape' >
                                    <div className="mt-2">
                                        <label className='mb-2' htmlFor="username" >Username</label>
                                        <input type="text" className="form-control"
                                            placeholder="johndeo"
                                            id="username" name="username"
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.username && formik.errors.username && <p style={{ color: "red", fontSize: "13px", marginBottom: 5, padding: 0 }}>{formik.errors.username}</p>}
                                        <label className='mb-2' htmlFor="Email">Email</label>
                                        <input type="email" className="form-control "
                                            placeholder="Enter-Email"
                                            id="Email" name="Email"
                                            onBlur={chekemail}
                                            onChange={(e) => setUseremail(e.target.value)}
                                        />
                                        {formik.touched.Email && formik.errors.Email && <p style={{ color: "red", fontSize: "13px", marginBottom: 5, padding: 0 }}>{formik.errors.Email}</p>}
                                        <div className="countdown-text-email">
                                            {seconds > 0 || minutes > 0 ? (
                                                <p>
                                                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                    {seconds < 10 ? `0${seconds}` : seconds}
                                                </p>
                                            ) : (
                                                <p>Didn't recieve OTP?</p>
                                            )}

                                            <button className='btn btn-dark' type='button'
                                                disabled={seconds > 0 || minutes > 0 || resendemaileotp == false}
                                                style={{
                                                    color: seconds > 0 || minutes > 0 || resendemaileotp == false ? "#DFE3E8" : "#FF5630",
                                                }}

                                                onClick={postemailotp}
                                            >
                                                Resend OTP
                                            </button>
                                        </div>

                                        <button type='button' className='btn btn-dark col-md-12 mt-2' onClick={postemailotp} disabled={sendotpemail == true}>Send OTP</button>


                                    </div>
                                    <div className="new-pplatform mt-3">
                                        <p>Allready Had Account<a href="/SignIn"> ? Please Sign In</a></p>
                                    </div>
                                </div>
                            )}

                            {stapereg === 2 && (
                                <div className='secoend_Stape'>
                                    <div className="mt-2">
                                        <input type="text" className='form-control col-md-12 mt-2' placeholder='Enter OTP' id='emaileotp'
                                            onChange={(e) => setEmailotp(e.target.value)}
                                        >
                                        </input>
                                        <button className='btn btn-success col-md-12 mt-2' type='button' onClick={ckemailotp}>Verify OTP</button>

                                    </div>

                                    <div className="mt-2">

                                    </div>
                                </div>
                            )}

                            {stapereg === 3 && (
                                <div className='Thired_Stape' style={{ padding: "15px" }}>
                                    <div className="row">
                                        <label htmlFor="Password">Password</label>
                                        <Input.Password size="large" placeholder="********"
                                            id="password" name="password"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            onBlur={formik.handleBlur}

                                        />
                                        {formik.touched.password && formik.errors.password && <p style={{ color: "red", fontSize: "13px", marginBottom: 5, padding: 0 }}>{formik.errors.password}</p>}
                                        <label htmlFor="Password">Confirm-password</label>
                                        <Input.Password size="large" placeholder="********"
                                            id="confirmpassword" name="confirmpassword"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmpassword}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.confirmpassword && formik.errors.confirmpassword && <p style={{ color: "red", fontSize: "13px", marginBottom: 5, padding: 0 }}>{formik.errors.confirmpassword}</p>}
                                        <div className="col-3 mt-2">
                                            <label htmlFor="conterycode ">code</label>
                                            <input type="text" className="form-control" placeholder="+91" disabled
                                                id="conterycode" name="conterycode" value={"91"}

                                            />

                                        </div>
                                        <div className="col-md-9 mt-2">
                                            <label htmlFor="phone">phone</label>
                                            <input type="text" className="form-control" placeholder="0000000000"
                                                id="phone" name="phone"
                                                onBlur={chekmobile}
                                                onChange={(e) => setUsermobile(e.target.value)}

                                            />
                                            {formik.touched.phone && formik.errors.phone && <p style={{ color: "red", fontSize: "13px", marginBottom: 5, padding: 0 }}>{formik.errors.phone}</p>}
                                        </div>
                                        {/* <div className="countdown-text-mobile">
                                                                            {secondsmobile > 0 || minutesmobile > 0 ? (
                                                                                <p>
                                                                                    Time Remaining: {minutesmobile < 10 ? `0${minutesmobile}` : minutesmobile}:
                                                                                    {secondsmobile < 10 ? `0${secondsmobile}` : secondsmobile}
                                                                                </p>
                                                                            ) : (
                                                                                <p>Didn't recieve OTP?</p>
                                                                            )}
                                
                                                                            <button className='btn btn-dark' type='button'
                                                                                disabled={secondsmobile > 0 || minutesmobile > 0 || resendmobileotp == false}
                                                                                style={{
                                                                                    color: secondsmobile > 0 || minutesmobile > 0 || resendmobileotp == false ? "#DFE3E8" : "#FF5630",
                                                                                }}
                                
                                                                                onClick={postmobileotp}
                                                                            >
                                                                                Resend OTP
                                                                            </button>
                                                                        </div> */}
                                    </div>

                                    <div className="form-check ">
                                        <label className="form-check label">

                                            <input type="checkbox" className="form-check-input "
                                                name="iagree"
                                                onChange={formik.handleChange}
                                                value={formik.values.iagree}
                                                onBlur={formik.handleBlur}
                                            /> I agree to privacy policy & terms

                                        </label>

                                    </div>

                                    <div className="mt-3 mb-3">
                                        <label htmlFor="Referal" className="mb-3 ">Referal By optional </label>
                                        <input type="Referal" className="form-control"
                                            id="referalcode" name="referalcode"
                                            onChange={formik.handleChange}
                                            value={formik.values.referalcode}
                                            onBlur={formik.handleBlur}
                                            placeholder="Referal code if you have optional" />
                                    </div>

                                    <div className="d-grid gap-2 mt-3">
                                        <button type='button' disabled={valiedemailbyotp == false} onClick={regster} className="btn btn-primary" >Singup</button>
                                    </div>

                                    <div className="row">
                                        <div className="creaccount col-md-12 mt-3">
                                            Already have an account ?<a className="Whitetrade-bottun" href="/SignIn"> Login instead</a>
                                        </div>
                                    </div>

                                </div>
                            )}

                            {/* <button type='button' className='btn btn-dark col-md-12 mt-2' onClick={postmobileotp} disabled={sendotpmobile == true}>Send OTP</button>
                                <input type="text" className='form-control col-md-12 mt-2' placeholder='Enter OTP' id='mobileotp'
                                    onChange={(e) => setMobileotp(e.target.value)}
                                >
                                </input>
                                <button className='btn btn-success col-md-12 mt-2' type='button' onClick={ckmobileotp}>Verify OTP</button> */}

                        </form>
                    </div>
                </div>
                <div className="registerright col-md-8">
                    <img src={require("../assets/images/6368592.jpg")} className="img-fluid" alt="“Vecteezy.com”" style={{ height: "500px", objectFit: "cover" }}></img>
                    <p>Vecteezy.com</p>
                </div>
            </div>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"

            />
        </div>

    )

}

export default Signup