import React from 'react';
import { Container, Card, CardContent, Typography, Divider } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Card elevation={3} sx={{ borderRadius: '16px', padding: 3 }}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
            Privacy Policy
          </Typography>

          <Divider sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
            Information We Collect
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            We collect personal information, including name, email, payment details, and other relevant data when you sign up for a membership plan. 
          </Typography>

          <Divider sx={{ mt: 3, mb: 3 }} />
          <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
            How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            We use your information to process payments, provide membership services, and improve user experience. Your data will not be shared with third parties without your consent, except as required by law.
          </Typography>

          <Divider sx={{ mt: 3, mb: 3 }} />
          <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
            Data Security
          </Typography>
          <Typography variant="body1" paragraph sx={{ color: '#555' }}>
            We implement industry-standard security measures to protect your data from unauthorized access. However, we cannot guarantee absolute security.
          </Typography>

          <Divider sx={{ mt: 4 }} />
          <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 3 }}>
            By using our services, you agree to our Privacy Policy.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PrivacyPolicy;
