import * as actiontype from '../actions/Actions';
import { fetchuser } from '../actions/auth';

const initialState = {
    error: null,
    isloding: false,
}


const reducer = (state=initialState,action) => 
{
    switch(action.type) 
    {
        case actiontype.FTCH_USER : return fetchuser(state,action)
        default : return state;
    }
}

export default reducer 