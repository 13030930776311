import { Row } from "react-bootstrap"
import Header from "./Header"
import { Button, Input, Modal,Form } from 'antd';
const Papertrading = () => {

	return (

		<div style={{marginTop:"150px"}}>
			<div className="container">
				<div className="paper-Trading">
					<div className="col-md-4 mb-3 mt-3">
						<div className="treding">
							<h5>Paper-Trading</h5>
						</div>
					</div>
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-2">
								<label>Total No of Trades</label>
								<Input type="text" id="Amount" name="Amount" size={10} className="mb-2 mt-2"></Input>
							</div>
							<div className="col-md-2">
								<label>Total No of Qty</label>
								<Input type="text" id="Amount" name="Amount" size={10} className="mb-2 mt-2"></Input>
							</div>
							<div className="col-md-2">
								<label>Profit & Loss</label>
								<Input type="text" id="Amount" name="Amount" size={10} className="mb-2 mt-2"></Input>
							</div>
							<div className="col-md-2">
								<label>Profit & Loss Chg</label>
								<Input type="text" id="Amount" name="Amount" size={10} className="mb-2 mt-2"></Input>
							</div>
						</div>
					</div>
					<table className="table table-bordered">
						<thead>
							<tr>
								<th scope="col">Sr.No.</th>
								<th scope="col">Date Time</th>
								<th scope="col">Active</th>
								<th scope="col">product</th>
								<th scope="col">Instrument</th>
								<th scope="col">Qty</th>
								<th scope="col">AVG</th>
								<th scope="col">LTP</th>
								<th scope="col">P&L</th>
								<th scope="col">Chg</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">1</th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</tbody>
						<tfoot>
						</tfoot>
					</table>
				</div>
			</div>
		</div>

	)
}

export default Papertrading