import https from '../../utility/api-client'

class Gethookdatas
{
    
    gethookdatas(userid)
    {
        return https.get('/Admin/GetHookdata?UserId='+ userid);
    }
}

export default new Gethookdatas();