import https from '../../utility/api-client'

class Invoicedelservice
{
    
    invoicedel(invoiceid)
    {
        return https.get('/Inv/DelInv?InvId='+invoiceid);
    }
}

export default new Invoicedelservice();