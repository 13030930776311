import https from '../../utility/api-client'

class Gettragelogerror {
    
    gettradelogerror(userid)
    {
        return https.get('/Admin/GetTradeError?UserId='+ userid);
    }
}

export default new Gettragelogerror();