import https from '../../utility/api-client'

class Telegramtokenchek
{
    
    telegramtokencheck(tokenid)
    {
        return https.get('/Telegram/GetTokenCheck?TokenId='+ tokenid);
    }
}

export default new Telegramtokenchek();