
import { Button, Table, Space, Modal, Input, Switch } from 'antd';
import { useEffect, useState } from 'react';
import AlluserList from '../store/services/alluserList';
import Loaidngcom from "../component/loadingcomponent"
import { Redirect, useHistory } from 'react-router-dom';
import * as actions from '../store/actions/Index'
import { useDispatch, useSelector, } from "react-redux";
import Header from '../component/Header';
const UserInfo = () => {
    const history = useHistory();
    let dispatch = useDispatch()
    const [alluserlist, setAlluserlist] = useState([])
    debugger

    useEffect(() => {
        const params = null;
        AlluserList.getalluser(params).then((apires) => {
            <Loaidngcom />
            debugger
            setAlluserlist(apires.data.data)
        })
            .catch((error) => {
                console.log("faild")
                debugger
            })


    }, [])

    const careateaction = (record) => {
        debugger
        dispatch(actions.userallinformation(record.id))
        let path = "/Userinformationdetail";
        history.push(path);
    }

    const columns = [
        {
            title: 'Userid',
            dataIndex: 'id',
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'profileName',
            align: "center"
        },
        {
            title: 'Created at',
            dataIndex: 'action',
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Button type='primary' ghost onClick={() => { careateaction(record) }}>
                            Test
                        </Button>
                    </>
                )
            }
        }
    ];


    return (
        <div>
            <div className='memberinformation'>
                <hr />
                <Table
                    bordered
                    dataSource={alluserlist}
                    columns={columns}
                />
            </div>
        </div>

    )
}

export default UserInfo