import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';
import { checkemail } from '../actions/auth';

const initialState = {
    error: null,
    isloding: false,
}


const reducer = (state=initialState,action) => 
{
    switch(action.type) 
    {
        case actiontype.CHECK_EMAIL : return checkemail(state,action)
        default : return state;
    }
}

export default reducer 