import Header from "./Header"


const Videospage =  () => {    

    return (
        <div>
            <Header/>
            <h1>Videospage</h1>
        </div>
    )

}

export default Videospage