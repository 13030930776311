import https from '../../utility/api-client'

class Telegramstatus
{
    
    telegramstatuschange(paramas)
    {
        return https.post('/Telegram/PostTelegramStatus',paramas);
    }
}

export default new Telegramstatus();