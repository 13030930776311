import https from '../../utility/api-client'

class Checkmobileotp
{
    
    chkmobileotp(email,otpnum)
    {
        return https.get('/OtpVerify/EmailOTPVerify?Email='+email+'&OTP='+otpnum);
     
    }
}

export default new Checkmobileotp();