import https from '../../utility/api-client'

class Invoicepostservice
{
    
    invoiceposting(params)
    {
        return https.post('/Inv/PostInv', params);
    }
}

export default new Invoicepostservice();