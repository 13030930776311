import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    invid:null,
    error: null,
    isloding: false,
}


const updateinvoice = (state, action) => {
    debugger
    return mergObject(state, {
        invid:action.invid,
        error: null,
        isloading: false,

    });
}




const reducer = (state=initialState,action) => 
{

    switch(action.type) 
    {
        case actiontype.UPDATE_INVOICE: return updateinvoice(state,action)
  
        default : return state;
    }
}

export default reducer