import https from '../../utility/api-client'

class Telegramtokendelete
{
    
    telegramtokendelet(parms)
    {
        return https.post('/Telegram/PostDeleteTeleBot',parms);
    }
}

export default new Telegramtokendelete();