import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    stglist: null,
    error: null,
    isloding: false,
}


const getoptainplan = (state, action) => {
    return mergObject(state, {
        userid: action.userid,
        error: null,
        isloading: false,

    });
}




const reducer = (state=initialState,action) => 
{

    switch(action.type) 
    {
        case actiontype.GET_OPTIAN_PLAN : return getoptainplan(state,action)
  
        default : return state;
    }
}

export default reducer