import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'; // Import all exports from the xlsx module
import { Card, Col, Row, Statistic, Space, Progress, Table, Button } from 'antd';
import Header from '../component/Header';
function ConvertData() {
    debugger
    const [tableData, setTableData] = useState([]);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        // You can fetch data here if needed
    }, []);

    const handleFileUpload = async (event) => {
        debugger
        const file = event.target.files[0];

        if (file) {
            try {
                const filename = file.name
                const changefile = filename.replace(/\.[^/.]+$/, ''); // Remove extension
                const data = await readFileAsText(file);
                const parsedData = parseData(data);
                const formattedData = formatDataAsTable(parsedData);
                setTableData(formattedData);
                setFileName(changefile)
            } catch (error) {
                console.error('Error reading or processing file:', error);
            }
        }
    };
    const generateExcelFile = () => {
        debugger
        if (tableData.length === 0 ) {
            return; // No data to export
        }
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(tableData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const fullFileName = `${fileName}.xlsx`; // Construct the full file name
        XLSX.writeFile(workbook, fullFileName);
    };
    const readFileAsText = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    };
    const parseData = (data) => {
        try {
            return JSON.parse(data);
        } catch (error) {
            console.error('Error parsing data:', error);
            return null;
        }
    };
    const formatDataAsTable = (parsedData) => {
        debugger
        if (!parsedData) return [];

        const headers = ["Timestamp", "Open", "High", "Low", "Close", "Volume"];
        const table = [headers];
        parsedData.candles.forEach(candle => {
            debugger
            const [timestamp, open, high, low, close, volume] = candle;
            const dateTime = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
            const formattedDate = dateTime.toLocaleString(); // Convert to local date string
            table.push([formattedDate, open, high, low, close, volume])
        });

        return table;
    };

    return (
        <div style={{marginTop:"110px"}}>
            <div >
     
                <input type="file" accept=".txt" onChange={handleFileUpload} />
                <Button onClick={generateExcelFile}>Export to Excel</Button>
                {tableData.length > 0 ? (
                    <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'center', paddingTop: 50 }}>
                        <thead>
                            <tr >
                                {tableData[0].map(header => (
                                    <th key={header} style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex} style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No data available.</p>
                )}

            </div>
        </div>

    );
}

export default ConvertData;


// export default ConvertData;
