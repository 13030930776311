
import { Table,Checkbox  } from 'antd';
import Orderservices from "../store/services/Orderservice"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loaidngcom from "./loadingcomponent"
import { MessageOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Tooltip } from 'antd';

const Orderpage = () => {

    const [orderdata, setOrderdata] = useState([])
    const authState = useSelector(state => state.authState)
    const userId = localStorage.getItem('userid');

    useEffect(() => {
        debugger
        let userid = userId
        
        Orderservices.orderser(userid).then((apires) => {
            <Loaidngcom />
            debugger
            console.log(apires);
            setOrderdata(apires.data.data)
        })
            .catch((error) => {
                console.log("faild")
                debugger
            })
    })



    const columns = [
        // {
        //     title: <Checkbox></Checkbox>,
        //     align: "center",
        //     render: (_, record) =>
        //     orderdata.length >= 1 ? (
        //         <Checkbox ></Checkbox>
        //     )
        //         : null
            
        // },
        {
            title: 'ClientId',
            dataIndex: 'clientId',
            align: "center",
            width:"20%"
        },
        {
            title: 'Time',
            dataIndex: 'tradeDate',
            align: "center",
            width:"20%"
        },
        {
            title: 'Type',
            dataIndex: 'tradeType',
            align: "center",
            width:"5%",
            render: (_, record) =>
                orderdata.length >= 1 ? (
                    <p style={{ color: record.tradeType == "Buy" ? 'Blue' : 'red' }}>
                        {record.tradeType}
                    </p>
                )
                    : null

        },
        {
            title: 'Instrument',
            dataIndex: 'tradeInstrument',
            align: "center",
            width:"5%",
        },

        {
            title: 'Product',
            dataIndex: 'tradeProduct',
            align: "center",
            width:"5%"
        },
        {
            title: 'Qty',
            dataIndex: 'tradeQty',
            align: "center",
            width:"5%"
        },
        {
            title: 'Price',
            dataIndex: 'tradeAmount',
            align: "center",
            width:"5%"
        },
        {
            title: 'Status',
            dataIndex: 's',
            align: "center",
            width:"5%",
            render: (_, record) =>
                orderdata.length >= 1 ? (
                    <p style={{ color: record.s == "Buy" ? 'Green' : 'red' }}>
                        {record.s}
                    </p>

                )
                    : null
        },
        {
            title: 'Message',
            dataIndex: 'message',
            align: "center",
            width:"5%",
            render: (_, record) =>
                orderdata.length >= 1 ? (
                    <Tooltip title={record.message} color={'volcano'}>
                        <MessageOutlined style={{color:"blueviolet"}} />
                    </Tooltip>
                )
                    : null
        }
    ]

    return (
        <div className='orderdiv'>
            <Table  
                columns={columns}
                dataSource={orderdata}
                bordered
                scroll={{ x: true}}
            >
            </Table>
        </div>
    )
}

export default Orderpage;