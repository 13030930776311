import { useEffect } from "react"
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory } from 'react-router-dom';
const Logoutpage = () => {
    const history = useHistory();

    useEffect(()=>{
        localStorage.removeItem('userid')
        localStorage.removeItem('username')
        localStorage.removeItem('auth')
        localStorage.removeItem('tstatus')
        localStorage.removeItem('useremail')
        localStorage.removeItem('setIsLoggedIn')
        let path = "/";
        history.push(path);
    },[])


    return (
        <>
     
        </>
    )
}

export default Logoutpage