import https from "../../utility/api-client";

class Userlogs
{
    userlogs (userid,logedfrom)
    {
        return https.get('/User/GetUserLog?UserId='+userid+'&UserFor='+logedfrom)
    }
}

export default new Userlogs();