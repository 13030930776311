import https from "../../utility/api-client";

class Userchaneauth
{
    chauth (params)
    {
        debugger
        return https.post('/User/PostUserStaus',params)
    }
}

export default new Userchaneauth();