import https from '../../utility/api-client'

class Fetchtradinglist
{
    
    fetchtradelist(userid,fyersid,straid,symbol,insrument)
    {
        return https.get('/Admin/GetTradelog?UserId='+userid+'&FyersId='+fyersid+'&StraId='+straid+'&Stymbol='+symbol+'&Instrument='+insrument);
    }
}

export default new Fetchtradinglist();