import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    planid: null,
    planPrice: null,
    planname: null,
    plandays:null,
    error: null,
    isloding: false,
}


const membershipoptain = (state, action) => {
    return mergObject(state, {
        planid: action.planid,
        planPrice: action.planPrice,
        planname:action.planname,
        plandays:action.plandays,
        error: null,
        isloading: false,

    });
}




const reducer = (state=initialState,action) => 
{

    switch(action.type) 
    {
        case actiontype.MEMBERSHIP_OPTAIN : return membershipoptain(state,action)
  
        default : return state;
    }
}

export default reducer