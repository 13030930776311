import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';


const initialState = {
    error: null,
    isloding: true,
}


const posttradingtada = (state, action) => {
    return mergObject(state, {
        error: null,
        isloading: false,

    });
}




const reducer = (state=initialState,action) => 
{

    switch(action.type) 
    {
        case actiontype.POST_TRADINGVIEWDATA : return posttradingtada(state,action)
  
        default : return state;
    }
}

export default reducer