import https from "../../utility/api-client";
class Statargeyservives
{
    Getstargelist(params)
    {
        return https.get('/App/GetStrategys',params)
    }
}

export default new Statargeyservives();


// return [
//     { id: "1", stgname: "STATRGEY1", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "100" },
//     { id: "2", stgname: "STATRGEY2", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
//     { id: "3", stgname: "STATRGEY3", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
//     { id: "4", stgname: "STATRGEY4", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
//     { id: "5", stgname: "STATRGEY5", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
//     { id: "6", stgname: "STATRGEY6", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
//     { id: "7", stgname: "STATRGEY7", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
//     { id: "8", stgname: "STATRGEY8", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
//     { id: "9", stgname: "STATRGEY9", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
//     { id: "10", stgname: "STATRGEY10", stgtime: "This Statagey is intraday time 9.20 to 3.00 ", stgtype: "INTRADAY", stgamt: "150", stgdiscount: "50", stgtotal: "1000" },
// ]