import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';
import { emailotpsend,mobileotpsend } from '../actions/auth';


const initialState = {
    error: null,
    isloding: false,
}


const reducer = (state=initialState,action) => 
{
    switch(action.type) 
    {
        case actiontype.CREATE_OTP_MOBILE : return mobileotpsend(state,action)
        case actiontype.CREATE_OTP_EMAIL : return emailotpsend(state,action)
        default : return state;
    }
}

export default reducer 