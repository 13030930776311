import https from '../../utility/api-client'

class Fyerstatageylistforactvie
{
    
    fyersactivelist(uid,brokoerid,apiid)
    {
        return https.get('Straallow/GetStraAllow?user_id='+uid+'+&broker_id='+brokoerid+'&Api_ID='+apiid);
    }
}

export default new Fyerstatageylistforactvie();