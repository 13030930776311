import https from '../../utility/api-client'

class Fyersapicount
{
    
    fyerscountapi(uid)
    {
        return https.get('Fyers/GetFCount?user_id='+uid);
    }
}

export default new Fyersapicount();