import https from '../../utility/api-client'

class Telegramtokenlist
{
    
    telegramtokenlist(userid)
    {
        return https.get('/Telegram/GetTokenList?UserId='+userid);
    }
}

export default new Telegramtokenlist();