import https from "../../utility/api-client";

class Repaymentinv
{
     repayInv (uerid,invid)
    {
        return https.get('/Inv/GetInv?user_id='+uerid+'&Inv_id='+ invid)
    }
}

export default new Repaymentinv();