import { Form, Formik, Field, ErrorMessage, useFormik, validateYupSchema } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../store/actions/Index'

import * as Yup from 'yup';
import Header from './Header';
import Poup from './Popupactiveapi';
import Editapi from './Popupeditactiveapi'
import { Button } from 'antd';
import CreateAPI from './Apicreation_fyers'
import CreateMata5API from './Apicreation_Meta5';
import apicration from '../store/services/apicartionservices'
import Loaidngcom from "../component/loadingcomponent"
import Allapiactive from './Allapiactivation';
const Apiactivaction = () => {
    const formik = useFormik({
        initialValues: {
            appname: "",
            apikey: "",
            tradeAmont: "",
            averageqty: "",

        },
        validateOnMount: true,
        validationSchema: Yup.object({
            appname: Yup.string()
                .required("This is required"),
            apikey: Yup.string()
                .required("This is required"),
            tradeAmont: Yup.number()
                .required("This is required")
                .typeError("Enter Valid Tarade Amount"),
            averageqty: Yup.string()
                .required("required")
        }),

    })

    const apicreate = useSelector(state => state.apicreate);
    const dispatch = useDispatch();

    const apicrating = (event) => {
        dispatch(action.apicreatestart());
        const params = null;
        apicration.apicre(params).then((apiRes) => {
            <Loaidngcom />
            dispatch(action.apicreatestart(apiRes));
            console.log(apiRes)

        })
            .catch((error) => {
                dispatch(action.registartionFaild(error));
                console.log("faild")
            });
    }

    return (
        <div>
            <div className='container py-5'>
                <h5 className='mt-5' style={{ textAlign: "left" }}>Select any broker to creaate API</h5>
                <hr />
                <div className="activationdiv row">
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='fyersacard col-md-6'>
                                <div className="card">
                                    <img src={require("../assets/images/Fyers.png")} height={100} width={75} className="card-img-top" alt="..." />
                                    <div className="card-body ml-5">
                                        <CreateAPI />
                                    </div>
                                </div>
                            </div>
                            <div className='fyersacard col-md-6'>
                                <div className="card">
                                    <img src={require("../assets/images/MetaTrader5-removebg.png")} height={100} width={75} className="card-img-top" alt="..." />
                                    <div className="card-body  ml-5">
                                        <CreateMata5API />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-6'>
                        <Allapiactive/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Apiactivaction