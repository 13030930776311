import https from '../../utility/api-client'

class Getstatargeyalloid
{
    
    getalloid(uid,brokoerid,apiid)
    {
        return https.get('Straallow/GetStraAllowH?user_id='+uid+'+&broker_id='+brokoerid+'&Api_ID='+apiid);
    }
}

export default new Getstatargeyalloid();