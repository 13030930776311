import * as actiontype from '../actions/Actions';
import { mergObject } from '../../utility/util';
import { apicreatesuccess } from '../actions/auth';


const initialState = {
    isloding: false,
    error:null,
}

const reducer = (state=initialState,action) => 
{
    switch(action.type) {
        case actiontype.APICREATE_START:return mergObject(state,{error:null,isloding:true})
        case actiontype.APICREATE_SUCCESS : return apicreatesuccess(state,action)
        case actiontype.APICREATE_FAIL : return mergObject(state,{error:action.error,isloding:false})
        default: return state;

    }
}

export default reducer;