import { Table } from 'antd';
import Positionservices from "../store/services/Positionservice"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loaidngcom from "./loadingcomponent"
import { Button, Divider, Space, Tooltip } from 'antd';
const Positionpage = () => {


    const [positiondata, setPositiondata] = useState([])
    const authState = useSelector(state => state.authState)
    
		const userId = localStorage.getItem('userid');
    const [msg, setMsg] = useState("hello")
    useEffect(() => {
        debugger
        let userid = userId
        Positionservices.positionser(userid).then((apires) => {
            <Loaidngcom />
            debugger
            console.log(apires);
            setPositiondata(apires.data.data)
        })
            .catch((error) => {
                console.log("faild")
                debugger
            })
    })



    const columns = [
        {
            title: 'Product',
            dataIndex: 'tProduct',
            align: "center",
            width:"5%"
        },
        {
            title: 'Instrument',
            dataIndex: 'tInstrument',
            align: "center",
            width:"15%"
        },

        {
            title: 'Qty',
            dataIndex: 'tQty',
            align: "center",
            width:"5%"
        },
        {
            title: 'LTP',
            dataIndex: 'tLtp',
            align: "center",
            width:"5%"
        },
        {
            title: 'P&L',
            dataIndex: 'tProfitLoss',
            align: "center",
            width:"5%",
            render: (_, record) =>
                positiondata.length >= 1 ? (
                    <p style={{color:record.tProfitLoss >= 0 ? 'Green' : 'red' }}>
                        {record.s}
                    </p>

                )
                    : null
        },

    ]

    return (
        <div className='positiondiv'>
            <Table style={{width:"75%"}}
                columns={columns}
                dataSource={positiondata}
                bordered
                scroll={{ x: true}}
            >
            </Table>
        </div>
    )

}

export default Positionpage