import https from "../../utility/api-client";

class USerSocedit 
{
    usersoced (params)
    {
        return https.post('/User/PostUser',params)
    }
}

export default new USerSocedit();