import https from "../../utility/api-client";

class Fayersexitbycatagery
{
    exitposition(params)
    {
        return https.post('/Fyers/PostExitPosition',params);
    }
}

export default new Fayersexitbycatagery();
