
const Clientsection = () => {
    return (
        <div className="clients py-5 ">
            <div className="container">
                <div className="clinenthead">
                    <h2 className="py-5" style={{textAlign:'center',fontSize:"2.5rem",fontWeight:"bolder"}}>Turst by clients across the globe</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="clients-dec col-md-4">
                            <img src={require("../assets/images/Fyers.png")} height={100} />
                            <div className="client-btn">
                                <a className="btn btn-dark" href="https://open-account.fyers.in/" role="button">Click Heare</a>
                            </div>
                        </div>
                        <div className="clients-dec col-md-4">
                            <img src={require("../assets/images/MetaTrader5-removebg.png")} height={100} />
                            <div className="client-btn">
                                <a className="btn btn-dark" href="https://www.metatrader5.com/" role="button">Click Heare</a>
                            </div>
                        </div>
                        <div className="clients-dec col-md-4">
                            <img src={require("../assets/images/CMSPrime-removebg.png")} height={100} />
                            <div className="client-btn">
                                <a className="btn btn-dark" href="https://cmsprime.com/en/" role="button">Click Heare</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clientsection