import https from "../../utility/api-client";

class MembershipRenewPlan
{
    memshiprenew (params)
    {
        return https.post('/Inv/PostRenew',params)
    }
}

export default new MembershipRenewPlan();