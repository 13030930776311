import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';



const initialState = {

    error: null,
    isloding: false,
}


const telegramtoken = (state, action) => {
    return mergObject(state, {
 
        error: null,
        isloading: false,

    });
}

const telegramtokendelete = (state, action) => {
    return mergObject(state, {
 
        error: null,
        isloading: false,

    });
}




const reducer = (state=initialState,action) => 
{

    switch(action.type) 
    {
        case actiontype.TELEGRAM_TOKEN_POST : return telegramtoken(state,action)

        case actiontype.TELEGRAM_TOKEN_DELETE : return telegramtokendelete(state,action)
  
        default : return state;
    }
}

export default reducer