import https from '../../utility/api-client'

class Createemailotp{
    
    creemailotp(email)
    {
        return https.post('/OtpVerify/EmailOTP', email);
    }
}

export default new Createemailotp();