import https from '../../utility/api-client'

class Perchedmembershipplan
{
    
    optmemrshipplan(id)
    {
        // return http.get('/Inv/GetInv?user_id='+ id+'&inv_id= '+2);
        return https.get('/Inv/GetInvH?user_id='+ id);
    }
}

export default new Perchedmembershipplan();