import Clientsection from "./Clientsection"
import { Button, Form, Input, Select } from "antd"
import Header from "../component/Header"
import { useEffect, useState, useRef } from 'react';
import Combostatargey from "../store/services/combostrategy"
import Comboticker from "../store/services/comboticker"
import Loaidngcom from "../component/loadingcomponent"
import Posttradingdata from "../store/services/Tradingdatapost"
import { SleddingTwoTone } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';



const Resorces = () => {
	return (

		<div>
			<Header/>
			<h1>Resorces Page</h1>
		</div>


	)
}

export default Resorces