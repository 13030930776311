import https from '../../utility/api-client'

class Fayersvalidation
{
    
    fayersvalidationchek(clid,appid,secid)
    {
        return https.get('/Fyers/GetFValidation?ClientId='+clid+'&AppId='+appid+'&SecretId='+secid);
    }
}

export default new Fayersvalidation();