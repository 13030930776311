import https from '../../utility/api-client'

class Resetpassforgot
{
    
    resetfgpassword(params)
    {
        return https.post('/User/PostResetpass', params);
    }
}

export default new Resetpassforgot();