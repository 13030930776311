import https from '../../utility/api-client'

class Combofillappid
{
    
    fillappid(params)
    {
        return https.get('/Admin/GetComboApiId?UserId=0',params);
    }
}

export default new Combofillappid();