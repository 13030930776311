import * as actiontype from '../actions/Actions';
import {mergObject} from '../../utility/util';
import {wallatebalsuccess} from '../actions/Index'

const initialState = {
    error: null,
    isloding: false,
}

const reducer = (state=initialState,action) => 
{
    switch(action.type) 
    {
        case actiontype.WALLATEBAL_START : return mergObject(state,{error:null,isloding:true})
        case actiontype.WALLATEBAL_SUCCESS : return wallatebalsuccess(state,action)
        case actiontype.WALLATEBAL__FAIL: return mergObject(state,{error:action.error,isloding:false})
        default : return state;
    }
}

export default reducer 