import { Table } from "antd";
import { useEffect,useState } from "react";
import tradeerrolog from "../store/services/Tradeerrolog"
import { useDispatch, useSelector } from 'react-redux';
import Loaidngcom from "./loadingcomponent"
import { MessageOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
const Tradingerrorlog = () => {
    const [tradelogdata, setTradelogdata] = useState([])
	const authState = useSelector(state => state.authState)
	const userId = localStorage.getItem('userid')
    useEffect(()=>{
        let userid = userId
		tradeerrolog.gettradelogerror(userid).then((apires) => {
	
			console.log(apires);
			setTradelogdata(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")
				
			})
    },[])

    const columns = [
        {
            title: "ErrorDate ",
            dataIndex: "errorDate",
            align: "center",
            width:"15%"
        },
        {
            title: 'ErrorFrom ',
            dataIndex: 'errorFrom',
            align: "center",
            width:"15%"
        },
        {
            title: 'Message',
            dataIndex: 'message',
            align: "center",
            width:"15%",
            render: (_, record) =>
            tradelogdata.length >= 1 ? (
                <Tooltip title={record.message} color={'volcano'}>
                   <h4><MessageOutlined style={{color:"blueviolet"}}/></h4> 
                </Tooltip>
            )
                : null
        },
        {
            title: 'RefId',
            dataIndex: 'refId',
            align: "center",
            width:"15%",
        }

    ];

    return (
        <div className="tradinglog">
       <Table
        columns={columns}
        dataSource={tradelogdata}
        bordered
        scroll={{ x: true}}
    >
    </Table>
    </div>

    )

}
export default Tradingerrorlog