import https from "../../utility/api-client";

class Meta5deletion
{
    meta5deletion(params)
    {
        return https.post('/MetaTrader/PostMtDel',params);
    }
}

export default new Meta5deletion();
